const MinegocioAudienceMgmtLogo = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#FFFF54" />
    <path
      d="M30 34V31.6667C30 30.429 29.4996 29.242 28.6088 28.3668C27.718 27.4917 26.5098 27 25.25 27H15.75C14.4902 27 13.282 27.4917 12.3912 28.3668C11.5004 29.242 11 30.429 11 31.6667V34"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5 22C22.9853 22 25 19.9853 25 17.5C25 15.0147 22.9853 13 20.5 13C18.0147 13 16 15.0147 16 17.5C16 19.9853 18.0147 22 20.5 22Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37 34V31.615C36.9993 30.5581 36.7044 29.5314 36.1614 28.6961C35.6184 27.8608 34.8581 27.2642 34 27"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29 13C30.1442 13.2558 31.1584 13.8369 31.8826 14.6517C32.6069 15.4665 33 16.4686 33 17.5C33 18.5314 32.6069 19.5335 31.8826 20.3483C31.1584 21.1631 30.1442 21.7442 29 22"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MinegocioAudienceMgmtLogo;
