const BeesMoneyCombLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Brand/Hub/AppIcon/Lift</title>
    <g
      id="Brand/Hub/AppIcon/Lift"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Brand/Lift/Icon/OnColor"
        transform="translate(13.142857, 12.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <g id="BEES_Lift_Icon_sRGB_Black">
          <path
            d="M5.93883498,21.1541135 C6.71570166,21.7141158 7.45879793,22.0863106 7.84666446,22.1339426 C6.78252829,23.4781219 4.85815935,23.8611299 4.16693732,23.9633692 C4.01767417,23.9819253 3.87902166,23.8819773 3.8501258,23.7349731 C3.73250186,23.0524484 3.49820622,21.1277692 4.43267946,19.7034057 C4.62700382,20.059189 5.19626016,20.618864 5.93883498,21.1541135 Z M18.828587,17.9972576 L4.18478042,17.9972576 C4.5713433,18.7239942 5.40269154,19.6182439 6.49966856,20.415119 C8.112656,21.5867964 9.71357043,22.1453426 10.4407281,21.8228001 C10.5661966,21.7789832 10.7266712,21.6954016 12.627563,20.0998454 L15.1060999,23.8461631 C15.1726658,23.9468109 15.2799171,23.9978854 15.3878827,23.9993076 C15.4989656,24.0062266 15.612996,23.9613714 15.686477,23.8610735 L19.0381131,19.2957126 C19.1389857,19.1579526 19.166155,18.979886 19.1107325,18.8185582 L18.828587,17.9972576 Z M21.779252,0.929050118 L21.7788326,0.928734077 C21.7039459,0.508049473 21.5947334,0.277170141 21.4675758,0.150561807 C21.2803819,-0.0358234492 21.0883814,-0.00969362017 20.5183995,0.023648719 C17.0419523,0.259855602 14.4583633,1.54899866 14.4583633,1.54899866 C10.5627391,3.44673534 8.71850545,6.00623957 8.71850545,6.00623957 L22.2668427,6.00623957 C22.3687887,3.83917941 22.036458,1.96609394 21.779252,0.929050118 Z M2.42569262,9.07073238 L0.0702777376,12.2852431 C-0.0796655804,12.4898911 0.0216683063,12.7806941 0.266721962,12.8488009 L5.03874429,14.17544 C4.92717386,14.4448989 4.8149686,14.7228119 4.70274067,15.0016279 L18.7388273,15.0016279 C21.2371798,12.0038988 21.751093,9.00254651 21.751093,9.00254651 L2.478111,9.00254651 L2.42569262,9.07073238 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default BeesMoneyCombLogo;
