import { Card } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const CardStyled = styled(Card, {
  padding: '16px 24px',
  height: '128px',
  cursor: 'pointer',
  width: '100%',
});

export const CardContent = styled('div', {
  display: 'flex',
  gap: '24px',
  width: '100%',
  height: '100%',
  alignItems: 'center',
});
