import { styled } from '@hexa-ui/theme';
import { TOPBAR_HEIGHT } from '../../constants';

export const StyledContainer = styled('div', {
  position: 'fixed',
  top: TOPBAR_HEIGHT,
  left: '0',
  right: '0',
  width: '100%',
  zIndex: '$8',
  transition: 'top 150ms cubic-bezier(0.0, 0.0, 0.2, 1)',

  variants: {
    topbarHidden: {
      true: {
        top: 0,
      },
    },
  },
});

export const StyledHeaderRow = styled('div', {
  height: TOPBAR_HEIGHT,
  alignItems: 'center',
  padding: '0 $2',
  boxSizing: 'border-box',
  backgroundColor: '$neutral0',
  boxShadow: '$2',
  '@desktopSmall': {
    padding: '0 $6',
  },
});

export const SidebarButton = styled('button', {
  all: 'unset',
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const PageTitle = styled('div', {
  color: '$interfaceLabelPrimary',
  fontFamily: '$heading',
  fontSize: '$5',
  fontWeight: '$semibold',
  marginLeft: '$1',
  lineHeight: '$4',
});
