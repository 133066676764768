import { useAuthContext } from 'contexts';
import { useGetApps } from 'hooks/useGetApps';
import { App } from 'models/Tool';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type HomeApp = {
  identifier: string;
  name: string;
};

export const useCurrentApp = () => {
  const [currentApp, setCurrentApp] = useState<App | HomeApp | undefined>(
    undefined,
  );
  const { pathname } = useLocation();
  const currentLocation = `/${pathname.split('/')?.[1]}`;
  const { data: allApps } = useGetApps();
  const { appsWithScope } = useAuthContext();

  useEffect(() => {
    let currentApp =
      currentLocation === '/'
        ? { identifier: 'HOME', name: 'Home' }
        : allApps?.data?.content?.find(
            (app) => app.appRoute === currentLocation,
          );

    if (!currentApp) {
      const mappedApps = appsWithScope?.map((app) => ({
        appRoute: app.baseRoute,
        identifier: app.id,
        name: app.appName['en-US'],
      }));

      currentApp = mappedApps?.find((app) => app.appRoute === currentLocation);
    }

    setCurrentApp(currentApp);
  }, [allApps, appsWithScope, pathname, currentLocation]);

  return currentApp;
};
