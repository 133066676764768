import { Portal } from '@hexa-ui/components';
import { ThemeProvider } from '@hexa-ui/components2';
import { useEnvContext } from 'contexts';
import { useAnalyticsLoginSuccessful } from 'hooks';
import { Navigate, useRoutes } from 'react-router-dom';
import { HandleAuthorization } from '../components';
import { Outlet } from '../components/Outlet/Outlet';
import {
  Dashboard,
  ErrorPage,
  RedirectToActivation,
  RedirectToDashboard,
  RedirectToFederatedLoginPage,
  RedirectToLoginPage,
} from '../pages';
import { AppsElements } from '../Routes';

export type RoutesType = {
  authRoutes: ReturnType<typeof useRoutes>;
  noAuthRoutes: ReturnType<typeof useRoutes>;
};

const mfeContentEl = document.getElementById('mfe-content');

export function useMakeRoutes(): RoutesType {
  const env = useEnvContext();
  useAnalyticsLoginSuccessful();

  const appsRoutes: Array<AppsElements> = env.appsConfiguration?.map((app) => ({
    path: app.baseRoute + '/*',
    element: (
      <HandleAuthorization
        permission={app.appScopePrefix}
        onlyFederatedAccess={app.onlyFederatedAccess ?? false}
        element={null}
      />
    ),
  }));

  const authRoutes = useRoutes([
    {
      path: '/',
      element: <Outlet />,
      children: mainRoutes.concat(appsRoutes),
    },
    {
      path: 'error/:errorCode',
      element: <ErrorPage />,
    },
    {
      path: '*',
      element: <Navigate to="/error/404" />,
    },
    {
      path: '/wp',
      element: <RedirectToDashboard />,
    },
    {
      path: '/login',
      element: <RedirectToDashboard />,
    },
    {
      path: '/activate',
      element: <RedirectToDashboard />,
    },
    {
      path: '/portal',
      element: <RedirectToDashboard />,
    },
  ]);
  const noAuthRoutes = useRoutes([
    {
      path: '/login',
      element: <RedirectToLoginPage />,
    },
    {
      path: '/wp',
      element: <RedirectToFederatedLoginPage />,
    },
    {
      path: '/activate',
      element: <RedirectToActivation />,
    },
    {
      path: '*',
      element: <Navigate to="/login" />,
    },
  ]);

  return { authRoutes, noAuthRoutes };
}

const mainRoutes: Array<AppsElements> = [
  {
    path: '/',
    element: (
      <Portal container={mfeContentEl}>
        <ThemeProvider>
          <Dashboard />
        </ThemeProvider>
      </Portal>
    ),
  },
];

export default useMakeRoutes;
