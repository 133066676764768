import { styled } from '@hexa-ui/theme';
import { breakpoints } from 'utils/constants';

export const HomeToolsContainer = styled('div', {
  display: 'grid',
  gridArea: 'homeTool',
  gap: '16px',
  width: '100%',
  gridTemplateColumns: '1fr',

  variants: {
    length: {
      single: {
        [`@media (min-width: ${breakpoints.desktopSmall})`]: {
          gridTemplateColumns: 'repeat(auto-fit, minmax(0, 1fr))',
        },
        [`@media (min-width: ${breakpoints.tablet})`]: {
          gridTemplateColumns: 'repeat(auto-fit, minmax(0, 1fr))',
        },
      },
      multiple: {
        [`@media (min-width: ${breakpoints.tablet})`]: {
          gridTemplateColumns: 'repeat(auto-fit, minmax(0, 1fr))',
        },
      },
    },
  },
});

export const LoadingContainer = styled('div', {
  height: '128px',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  gridArea: 'homeTool',
});
