import appHeader from 'i18n/messages/appHeader';
import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  Components: {
    Sidebar: {
      collapseMenu: 'Collapse menu',
    },
  },
  test: {
    translate: 'Translate',
    missing: 'Just English option available',
  },
  Error500: {
    title: 'Ooops! Something went wrong',
  },
  Error403: {
    title: 'Hmm... Access denied!',
    description: "It looks like you don't have permission to access this page.",
    button: 'Home',
  },
  Error404: {
    title: 'Mmm... Looks like this page is gone',
    description: "It looks like this page wasn't found or doesn't exist.",
    button: 'Home',
  },
  Error503: {
    title: 'Hmm… Service unavailable!',
    description:
      'Our servers are temporarily too busy. You can try again in a few minutes, and it should be normalized.',
    button: 'Try again',
    infoText: 'If the issue persists, you can <a>report the problem.</a>',
  },
  AvatarMenu: {
    signOut: 'Sign out',
    userProfile: 'User profile',
  },
  Dashboard: {
    errorPage: 'Nothing to see here',
    quickLinks: 'Quick links',
    quickLinksLoadingError:
      'An error has occurred and we could not load the quick links.',
    reload: 'Reload',
    searchPlaceholder: 'Search by tool name',
    sortBy: 'Sort by',
    titleAZ: 'Title A-Z',
    titleZA: 'Title Z-A',
    noToolFound: 'No tool found.',
    noQuickLinks: 'No quick links yet.',
  },
  Home: {
    greetings: 'Hi, {name}!',
    description:
      'You are on BEES One, a one-stop platform with multiple solutions for getting things done.',
    dtcDescription:
      'You are on a one-stop platform with multiple solutions for getting things done.',
    accessReportsCard: 'Access reports on performance within BEES',
    trackDataCard: 'Track data from the retailer app',
    btGuideCard: 'Check your business transformation score',
  },
  Notification: {
    title: 'Notifications',
    allTab: 'All',
    unreadTab: 'Unread',
    highPriorityTab: 'High priority',
    markAllRead: 'Mark all as read',
    markUnread: 'Mark as unread',
    markRead: 'Mark as read',
    highPriority: 'High priority.',
    noNotifications: 'No notifications yet.',
    noUnreadNotifications: 'No unread notifications yet.',
    noHighPriorityNotifications: 'No high-priority notifications yet.',
    noMoreNotifications: 'No more notifications to display.',
    showMore: 'Show more',
    yesterday: 'Yesterday, {value}',
    passwordChanged: 'Password changed successfully!',
    latestNotifications: 'Latest notifications',
    showAll: 'Show all notifications',
    error:
      'An error has occurred and we could not load the latest notifications.',
    reload: 'Reload',
  },
  AppSwitcher: {
    yourApps: 'Your tools',
    tools: 'Tools',
    reload: 'Reload',
    error: 'An error occurred and we could not load the menu.',
  },
  UpdatesPopover: {
    updates: 'UPDATES',
    title: 'New BEES One menu',
    description:
      'You can now easily navigate between your tools from this menu. Give it a try!',
    pagination: '1 of 1',
    gotIt: 'Got it',
  },
  ApprovalFlow: {
    showApprovalFlowButton: 'Approval flow',
  },
  AppCatalog: {
    showToolLibraryButton: 'Show tool library',
  },
  AdminTool: {
    showAdminToolButton: 'BEES One admin tool',
  },
  TermsAndConditions: {
    showTermsAndConditionsButton: 'Terms & conditions',
  },
  PrivacyPolicy: {
    showPrivacyPolicyButton: 'Privacy policy',
  },
  TermsAndPolicies: {
    titleFirstLogin: 'Terms & Conditions and Privacy Policy',
    titleUpdate: 'Terms & Conditions and Privacy Policy update',
    updateDescriptionLine:
      'We have updated our Terms & Conditions and Privacy Policy.',
    updateDescriptionParagraph1:
      'To proceed, please confirm you have read and understood the updated BEES ONE’s Terms & Conditions and Privacy Policy by checking both boxes below.',
    descriptionParagraph1:
      'To proceed, please confirm you have read and understood BEES ONE’s Terms and Conditions and Privacy Policy by checking both boxes below.',
    descriptionParagraph2:
      'Both documents are clickable, and will be displayed in the BEES ONE language you chose. You can always review the terms again by navigating to the specific document in the settings menu.',
    agreeTerms: "I have read and understood BEES One's {link}.",
    agreePolicies: "I have read and understood BEES One's {link}.",
    terms: 'Terms & conditions',
    policies: 'Privacy policy',
    termsLink: 'Terms & Conditions',
    policiesLink: 'Privacy Policy',
    mustCheckBoxError: 'You must check this box to proceed.',
    logoutButton: 'Log out',
    agreeButton: 'Proceed',
  },
  ...appHeader.enUS,
};

export default enUS;
