import { useAuthContext } from 'contexts';
import { useSegmentAnalytics } from 'hooks';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserIds } from 'utils';
import { OriginApp } from '../../../analytics';

export const useAnalyticsPageViewed = () => {
  const [location, setLocation] = useState<string>('');
  const { pathname } = useLocation();
  const { appsWithScope } = useAuthContext();
  const segmentAnalytics = useSegmentAnalytics();
  useEffect(() => {
    const currentLocation = `/${pathname.split('/')?.[1]}`;
    if (currentLocation !== location) {
      setLocation(currentLocation);
      const appName =
        currentLocation === '/'
          ? 'Dashboard'
          : appsWithScope.find((app) => app.baseRoute === currentLocation)
              ?.appName['en-US'];
      const { anonymousId, userId } = getUserIds();

      segmentAnalytics?.pageViewed({
        anonymousId,
        userId,
        properties: {
          app_name: appName,
          origin_app: OriginApp.AdminPortal,
        },
      });
    }
  }, [appsWithScope, location, pathname, segmentAnalytics]);
};
