/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  useGetCluster,
  usePreferredLanguageV2,
  useUserMetadata,
} from 'admin-portal-shared-services';
import { useEnvContext } from 'contexts';
import { getLatestDate } from 'helpers';

export function useTermsAndPrivacy() {
  const { data } = useUserMetadata();
  const { preferredLanguage: language } = usePreferredLanguageV2();
  const { termsAndConditions, privacyPolicy } = useEnvContext();
  const hasTermsAndPrivacyProps = !!termsAndConditions && !!privacyPolicy;
  const cluster = useGetCluster();
  const isGlobal = cluster === 'Global_US';
  const preferredLanguage = language?.toLowerCase();

  const latestTermsDate =
    hasTermsAndPrivacyProps &&
    preferredLanguage &&
    getLatestDate(termsAndConditions, preferredLanguage);

  const latestPrivacyDate =
    hasTermsAndPrivacyProps &&
    preferredLanguage &&
    getLatestDate(privacyPolicy, preferredLanguage);

  const userAcceptedTerms = data?.acceptedTerms?.reduce((prev, cur) => {
    try {
      prev.push(JSON.parse(cur));
      return prev;
    } catch {
      return prev;
    }
  }, []);

  const isFirstAccess = !userAcceptedTerms?.length;

  const isTermsUpdated = !userAcceptedTerms
    ?.filter((doc) => doc.type === 't&c' && doc.language === preferredLanguage)
    .map((doc) => doc.date)
    .includes(latestTermsDate);

  const isPrivacyUpdated = !userAcceptedTerms
    ?.filter(
      (doc) => doc.type === 'privacy' && doc.language === preferredLanguage,
    )
    .map((doc) => doc.date)
    .includes(latestPrivacyDate);

  const shouldOpenTermsAndPrivacyModal =
    isGlobal &&
    hasTermsAndPrivacyProps &&
    (isFirstAccess || isTermsUpdated || isPrivacyUpdated) &&
    !!data;

  return {
    isFirstAccess,
    isTermsUpdated,
    isPrivacyUpdated,
    shouldOpenTermsAndPrivacyModal,
  };
}
