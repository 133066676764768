import { styled } from '@hexa-ui/theme';

export const StyledIconContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$full',
  variants: {
    size: {
      small: {
        width: '1.25rem',
        height: '1.25rem',
        '& svg': {
          width: '1.25rem',
          height: '1.25rem',
        },
      },
      medium: {
        width: '1.5rem',
        height: '1.5rem',
        '& svg': {
          width: '1.5rem',
          height: '1.5rem',
        },
      },
    },
  },
});
