import { useAuthenticationService } from 'admin-portal-shared-services';
import axios from 'axios';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuthContext } from 'contexts';
import { useNavigateLogin } from 'hooks';

const baseURL = window.location.origin;

interface AzureJwtVerify {
  token: string | null;
  iss: string | null;
}

interface VerifyJwtTokenResponse {
  message: string;
  isValid: boolean;
}

export const useVerifyToken = () => {
  const location = useLocation();
  const { hasSession, decodedToken } = useAuthContext();
  const authenticationService = useAuthenticationService();
  const navigateLoginCallback = useNavigateLogin();

  useEffect(() => {
    if (!hasSession) return;

    const authHeader = authenticationService.getAuthHeader();
    const token = authHeader?.replace('Bearer ', '');

    verifyJwtToken({ token, iss: decodedToken.iss }).catch((error) => {
      if (error.response.status !== 401) navigateLoginCallback();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodedToken?.iss, hasSession, location.pathname]);
};

async function verifyJwtToken({
  token,
  iss,
}: AzureJwtVerify): Promise<VerifyJwtTokenResponse> {
  return axios
    .post(`${baseURL}/auth/token/verify`, { token, iss })
    .then((response) => response.data);
}
