import { useQuery } from '@tanstack/react-query';
import { appCatalogService } from 'services/AppCatalogService/AppCatalogService';

export const useGetApps = (locale = 'en-US') => {
  const response = useQuery({
    queryKey: ['apps', locale],
    queryFn: () => appCatalogService.getApps(locale),
  });

  return response;
};
