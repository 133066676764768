import { Card } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { breakpoints } from 'utils/constants';

export const GreetingsCard = styled(Card, {
  width: '100%',
  padding: '12px 16px',
  display: 'flex',
  gap: '24px',
  justifyContent: 'space-between',
  [`@media (min-width: ${breakpoints.desktopSmall})`]: {
    flexDirection: 'row !important',
  },
});

export const GreetingsContainer = styled('div', {
  display: 'flex',
  gap: '24px',
  alignItems: 'center',
});

export const GreetingsTextContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  justifyContent: 'center',
});

export const CountryVendorContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px',
  paddingLeft: '80px',
  flexDirection: 'column',
  [`@media (min-width: ${breakpoints.mobileLarge})`]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  [`@media (min-width: ${breakpoints.desktopSmall})`]: {
    paddingLeft: '0px',
  },
});

const generateGridTemplateAreas = (length, isLoading, isError) => {
  if (isError || length === 0) {
    return `
      "latestNotifications latestNotifications"
      "quickLinks quickLinks"
    `;
  }

  if (isLoading) {
    return `
      "homeTool homeTool"
      "latestNotifications latestNotifications"
      "quickLinks quickLinks"
    `;
  }

  if (length === 1) {
    return `
      "homeTool homeTool"
      "latestNotifications latestNotifications"
      "quickLinks quickLinks"
    `;
  }

  return `
    "homeTool homeTool"
    "latestNotifications latestNotifications"
    "quickLinks quickLinks"
  `;
};

const generateMediaGridTemplateAreas = (length, isLoading, isError) => {
  if (isError || length === 0) {
    return `
      "latestNotifications quickLinks"
    `;
  }

  if (isLoading) {
    return `
      "homeTool homeTool"
      "latestNotifications quickLinks"
    `;
  }

  if (length === 1) {
    return `
      "homeTool quickLinks"
      "latestNotifications quickLinks"
    `;
  }

  return `
    "homeTool homeTool"
    "latestNotifications quickLinks"
  `;
};

export const Container = styled('div', {
  display: 'grid',
  gap: '16px',
  width: '100%',
  gridTemplateColumns: '1fr 1fr',

  variants: {
    layout: {
      default: {},
      error: {},
      loading: {},
      singleTool: {},
      noTools: {},
    },
  },

  compoundVariants: [
    {
      layout: 'default',
      css: {
        gridTemplateAreas: generateGridTemplateAreas(2, false, false),
        [`@media (min-width: ${breakpoints.desktopSmall})`]: {
          gridTemplateAreas: generateMediaGridTemplateAreas(2, false, false),
        },
      },
    },
    {
      layout: 'error',
      css: {
        gridTemplateAreas: generateGridTemplateAreas(0, false, true),
        [`@media (min-width: ${breakpoints.desktopSmall})`]: {
          gridTemplateAreas: generateMediaGridTemplateAreas(0, false, true),
        },
      },
    },
    {
      layout: 'loading',
      css: {
        gridTemplateAreas: generateGridTemplateAreas(2, true, false),
        [`@media (min-width: ${breakpoints.desktopSmall})`]: {
          gridTemplateAreas: generateMediaGridTemplateAreas(2, true, false),
        },
      },
    },
    {
      layout: 'singleTool',
      css: {
        gridTemplateAreas: generateGridTemplateAreas(1, false, false),
        [`@media (min-width: ${breakpoints.desktopSmall})`]: {
          gridTemplateAreas: generateMediaGridTemplateAreas(1, false, false),
        },
      },
    },
    {
      layout: 'noTools',
      css: {
        gridTemplateAreas: generateGridTemplateAreas(0, false, false),
        [`@media (min-width: ${breakpoints.desktopSmall})`]: {
          gridTemplateAreas: generateMediaGridTemplateAreas(0, false, false),
        },
      },
    },
  ],
});

export const LatestNotificationsContainer = styled('div', {
  gridArea: 'latestNotifications',
});

export const QuickLinksContainer = styled('div', {
  gridArea: 'quickLinks',
});
