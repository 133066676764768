import { HubTheme } from 'admin-portal-shared-services';
import { createContext, useContext } from 'react';

export type AppTranslations = {
  'en-US': string;
  'es-419': string;
  'pt-BR': string;
};

export type AppsConfiguration = {
  authorizationApp: string;
  id: string;
  appName: AppTranslations;
  appDescription: AppTranslations;
  appScopePrefix: string;
  onlyFederatedAccess?: boolean;
  baseRoute: string;
  homeRoute: string;
  hidden: boolean;
  appIconBackgroundColor?: string;
};

export type EnvConfig = {
  appsConfiguration: Array<AppsConfiguration>;
  segmentKey: string;
  datadogAppId: string;
  datadogClientId: string;
  defaultTheme: HubTheme;
  defaultLogo: 'tada' | 'beesOne';
  termsAndConditions: {
    [key: string]: string;
  };
  privacyPolicy: {
    [key: string]: string;
  };
};

const defaultInitialValue: EnvConfig = {
  appsConfiguration: [],
  segmentKey: '',
  datadogAppId: '',
  datadogClientId: '',
  defaultTheme: null,
  defaultLogo: null,
  termsAndConditions: {},
  privacyPolicy: {},
};

export const EnvContext = createContext(defaultInitialValue);

export const useEnvContext = () => useContext(EnvContext);

export const EnvProvider = ({
  env,
  children,
}: {
  env: EnvConfig;
  children: React.ReactNode;
}) => <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
