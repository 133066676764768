import { convertToPascalCase } from 'helpers';
import { StyledIconContainer } from './AppIcon.styles';
import { iconsMap } from './icons';

interface AppIconProps {
  icon: string;
  dataTestId?: string;
  size?: 'small' | 'medium';
}

const AppIcon = ({
  icon,
  dataTestId,
  size = 'medium',
}: AppIconProps): JSX.Element => {
  const getIcon = (icon: string) => {
    const applicationIcon = iconsMap.get(convertToPascalCase(icon));

    return applicationIcon?.icon ?? <></>;
  };

  const IconComponent = getIcon(icon);

  return (
    <StyledIconContainer data-testid={dataTestId} size={size}>
      {IconComponent}
    </StyledIconContainer>
  );
};

export default AppIcon;
