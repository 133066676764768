import { RefObject, useEffect } from 'react';
import { Handler, PossibleEvent } from './useOnClickOutside.types';

export function useOnClickOutside(
  ref: RefObject<HTMLElement>,
  handler: Handler,
  ignoredRefs: RefObject<HTMLElement>[] = [],
) {
  useEffect(() => {
    const listener = (event: PossibleEvent) => {
      if (
        !ref.current ||
        ref.current.contains(event.target as Node) ||
        ignoredRefs.some(
          (ignoredRef) =>
            ignoredRef.current &&
            ignoredRef.current.contains(event.target as Node),
        )
      ) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, ignoredRefs]);
}
