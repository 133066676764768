import { useAuthContext } from 'contexts';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMakeRoutes, useVerifyToken } from './hooks';

export type AppsElements = {
  path: string;
  element: JSX.Element;
};

const localStorageKey = 'initialPath';

const Routes = () => {
  const { hasSession } = useAuthContext();
  const { authRoutes, noAuthRoutes } = useMakeRoutes();
  const location = useLocation();
  const navigate = useNavigate();

  useVerifyToken();

  useEffect(() => {
    const initialPath = localStorage.getItem(localStorageKey);
    const { pathname } = location;
    const shouldWriteToLocalStorage =
      ['/', '/login', '/wp', '/activate', '/portal'].indexOf(pathname) === -1 &&
      pathname.indexOf('/error') === -1;

    if (initialPath && hasSession) {
      navigate(initialPath);
      localStorage.removeItem(localStorageKey);
    } else if (!hasSession && shouldWriteToLocalStorage) {
      localStorage.setItem(localStorageKey, pathname);
    }
  }, [hasSession, location, navigate]);

  return hasSession ? authRoutes : noAuthRoutes;
};

export default Routes;
