import { Typography } from '@hexa-ui/components2';
import { styled } from '@hexa-ui/theme';
import tokens from '@hexa-ui/tokens/dist/json/nested.json';

export const DropdownMenuStyled = styled('div', {
  backgroundColor: 'white',
  width: '172px',
  height: '144px',
  borderRadius: '8px',
  position: 'absolute',
  zIndex: 999,
  fontFamily: 'Work Sans',
  fontSize: '16px',
  lineHeight: '24px',
  marginTop: '8px',
  boxShadow: tokens.shadow.elevation[1],
});

export const GroupName = styled(Typography.SubHeader, {
  margin: '16px 0 8px 16px',
  color: tokens.color.foundation.txt.secondary,
});

export const MenuOption = styled('div', {
  display: 'flex',
  justifyContent: 'start',
  padding: '12px 0px 12px 24px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: tokens.color.component.bg['dropdown-menu'].item.hover,
  },
});
