import { styled } from '@hexa-ui/theme';
import { breakpoints } from 'utils/constants';

export const Notification = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '12px 12px 16px 0px',
  borderBottom: '1px solid #1414141f',
  [`@media (min-width: ${breakpoints.mobileLarge})`]: {
    flexDirection: 'row',
    gap: '24px',
  },
});

export const ToolContainer = styled('div', {
  width: '100%',
  [`@media (min-width: ${breakpoints.mobileLarge})`]: {
    width: '30%',
  },
  [`@media (min-width: ${breakpoints.desktopSmall})`]: {
    width: '40%',
  },
});

export const MessageContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [`@media (min-width: ${breakpoints.mobileLarge})`]: {
    width: '70%',
  },
  [`@media (min-width: ${breakpoints.desktopSmall})`]: {
    width: '60%',
  },
});

export const TitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});

export const StatusContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const StatusButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',
  width: '8px',
  minWidth: '8px',
  height: '8px',
  borderRadius: '100%',
  cursor: 'pointer',
  '&:hover': {
    border: '0.0625rem solid #141414ff',
  },
  variants: {
    read: {
      true: {
        border: '0.125rem solid #1414141f',
      },
      false: {
        backgroundColor: '#006EDB',
      },
    },
  },
});
