import axios from 'Api/Api';
import { ANALYTICS_BUSINESS_SERVICE } from '../constants';

export type GetBTGuideUrlOptions = {
  vendorId: string;
  locale: string;
  country: string;
};

class AnalyticsBusinessService {
  getBTGuideURL({ vendorId, country, locale }: GetBTGuideUrlOptions) {
    return axios.get(`${ANALYTICS_BUSINESS_SERVICE}/bt-guide/${vendorId}`, {
      headers: {
        'accept-language': locale,
        country: country,
      },
    });
  }
}

export const analyticsBusinessService = new AnalyticsBusinessService();
