import { DropdownButton, Tooltip } from '@hexa-ui/components2';
import { Store, World } from '@hexa-ui/icons2';
import * as RadixSelect from '@radix-ui/react-select';
import { useState } from 'react';
import { toggleScrollbar } from 'utils';
import {
  SelectContent,
  SelectOption,
  SelectPopover,
  SelectValue,
} from './Select.styles';

export type SelectOptions = {
  label: string;
  value: string;
};

type SelectProps = {
  options: SelectOptions[];
  value: string;
  onChange: (value: string) => void;
  variant?: 'country' | 'vendor';
};

export const Select = ({
  options,
  value,
  onChange,
  variant = 'country',
}: SelectProps) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const checkOverflow = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    const element = event.currentTarget;
    setIsOverflow(element.offsetWidth < element.scrollWidth);
  };

  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
    toggleScrollbar(open);
  };

  return (
    <RadixSelect.Root
      open={isOpen}
      onOpenChange={(open) => onOpenChange(open)}
      value={value}
      onValueChange={(value) => onChange(value)}
    >
      <RadixSelect.Trigger asChild>
        <DropdownButton
          icon={variant === 'country' ? World : Store}
          size="small"
          open={isOpen}
          style={{
            maxHeight: '32px',
            boxSizing: 'border-box',
            width: 'fit-content',
          }}
          data-testid={`${variant}-select`}
        >
          <Tooltip
            content={options?.find((option) => option.value === value)?.label}
            position="top"
            style={{ zIndex: 9999 }}
            {...(!isOverflowed ? { open: false } : {})}
            data-testid="select-tooltip"
          >
            <SelectValue
              variant={variant}
              onMouseEnter={(event) => {
                checkOverflow(event);
              }}
              data-testid="select-value"
            >
              {options?.find((option) => option.value === value)?.label}
            </SelectValue>
          </Tooltip>
        </DropdownButton>
      </RadixSelect.Trigger>
      <SelectPopover>
        <SelectContent position="popper">
          {options?.map((option) => (
            <SelectOption key={option.value} value={option.value}>
              {option.label}
            </SelectOption>
          ))}
        </SelectContent>
      </SelectPopover>
    </RadixSelect.Root>
  );
};
