import { Typography } from '@hexa-ui/components2';
import { ChevronDown, ChevronUp } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import { Link } from 'react-router-dom';
import { TOPBAR_HEIGHT } from '../../constants';

export const StyledHeader = styled('div', {
  height: TOPBAR_HEIGHT,
  padding: '0 $4 0 $2',
  display: 'flex',
  alignItems: 'center',
  marginRight: 'var(--scrollbar-size)',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  '@desktopSmall': {
    padding: '0 $6',
  },
});

export const StyledTopBarNav = styled('div', {
  width: '100%',
  backgroundColor: '$neutral0',
  top: 0,
  position: 'fixed',
  zIndex: '$10',
  boxShadow: '$2',
  opacity: 1,
  transition: 'opacity 400ms ease-out',
});

export const StyledContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

const breakpoints = {
  small: '256px',
  medium: '288px',
  large: '320px',
};

export const StyledLink = styled(Link, {
  height: '0.625rem',
  display: 'flex',
  marginLeft: '0.5rem',
  [`@media (min-width: ${breakpoints.large})`]: {
    height: '1rem',
  },
  variants: {
    variantLogo: {
      tada: {
        height: '1.5rem',
        [`@media (min-width: ${breakpoints.medium})`]: {
          height: '1.8rem',
        },
        [`@media (min-width: ${breakpoints.large})`]: {
          height: '2rem',
        },
      },
      beesOne: {
        height: '0.625rem',
        [`@media (min-width: ${breakpoints.small})`]: {
          height: '0.8rem',
        },
        [`@media (min-width: ${breakpoints.medium})`]: {
          height: '0.9rem',
        },
        [`@media (min-width: ${breakpoints.large})`]: {
          height: '1rem',
        },
      },
    },
  },
});

export const StyledLogo = styled('img', {
  height: '100%',
});

export const StyledAppSwitcher = styled('button', {
  display: 'none',
  '@desktopSmall': {
    padding: '0 $4',
    cursor: 'pointer',
    height: '3.5rem',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    background: 'none',
  },
  variants: {
    shouldRender: {
      false: {
        display: 'none',
      },
    },
  },
});

export const StyledIconElContainer = styled('div', {
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  background: '$brandPrimaryBasis',
});

export const AppName = styled('p', {
  color: '$interfaceLabelPrimary',
  fontFamily: '$paragraph',
  fontSize: '$2',
  fontWeight: '$medium',
  lineHeight: '$6',
  marginLeft: '$1',
});

export const StyledChevronDown = styled(ChevronDown, {
  marginLeft: '$2',
});

export const StyledChevronUp = styled(ChevronUp, {
  marginLeft: '$2',
});

export const StyledAvatarButton = styled('button', {
  marginLeft: '$2',
  cursor: 'pointer',
  padding: 0,
  border: 'none',
  borderRadius: '50%',
  width: '2rem',
  height: '2rem',
  '&:focus': {
    border: '$neutral100',
  },
});

export const StyledIconButton = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '2.5rem',
  height: '2.5rem',
  backgroundColor: '$interfaceSurfaceClear',
  borderRadius: '50%',
  border: 'none',
  marginLeft: '$1',
  transition: 'all 0.3s ease-in-out',
  '&>svg': {
    color: '$interfaceLabelPrimary',
    fontSize: '16px',
  },
  '&:hover': {
    backgroundColor: '$interfaceSurfacePrimary',
  },
  '&:active': {
    backgroundColor: '$interfaceSurfaceSecondary',
    '&>svg': {
      color: '$interfaceLabelSecondary',
    },
  },
  '@desktopSmall': {
    display: 'none',
  },
  variants: {
    shouldRender: {
      false: {
        display: 'none',
      },
    },
  },
});

export const StyledLabel = styled(Typography.Body, {
  margin: 0,
  display: 'none',
  '@desktopSmall': {
    display: 'flex',
  },
});

export const DropdownButtonContainer = styled('div', {
  width: '100%',
  boxSizing: 'border-box',
  marginRight: '4px',
  backgroundColor: '#FFF',
  variants: {
    isModalOpen: {
      true: {
        margin: 0,
        border: '4px solid #006EDB',
        borderRadius: '8px',
        padding: '4px',
        position: 'relative',
        zIndex: 600,
        pointerEvents: 'none',
      },
    },
  },
});
