import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  position: 'absolute',
  top: '70px',
  zIndex: '600',
  backgroundColor: '#006EDB',
  color: '#FFF',
  borderRadius: '16px',
  boxShadow: '0px 0px 24px 0px #00000029',
  maxWidth: '450px',
  minWidth: '288px',
});

export const Arrow = styled('div', {
  backgroundColor: '#006EDB',
  width: '16px',
  height: '16px',
  transform: 'rotate(45deg)',
  position: 'absolute',
  top: '64px',
  zIndex: 601,
  left: '36px',
  '@desktopSmall': {
    left: '64px',
  },
});

export const ContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 24px 16px',
});

export const ActionContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 16px 16px',
});

export const ActionButton = styled('button', {
  boxSizing: 'border-box',
  height: '32px',
  padding: '8px 12px',
  border: '1px solid #006EDB',
  borderRadius: '128px',
  fontFamily: '$paragraph',
  fontWeight: '$medium',
  fontSize: '14px',
  lineHeight: '16px',
  color: '#006EDB',
  backgroundColor: '#FFF',
  cursor: 'pointer',
  '&:hover,&:focus': {
    borderColor: '#C3DFFEFF',
  },
  '&:active': {
    color: '#FFF',
    backgroundColor: '#006EDB',
  },
});
