import { NotificationTabs } from 'components/Notification/constants';
import { createContext, ReactNode, useContext, useState } from 'react';

interface NotificationContextProps {
  isNotificationsDropdownOpen: boolean;
  setIsNotificationsDropdownOpen: (value: boolean) => void;
  defaultTab?: NotificationTabs;
  setDefaultTab?: (value: NotificationTabs) => void;
}

export const NotificationContext = createContext<NotificationContextProps>({
  isNotificationsDropdownOpen: false,
  setIsNotificationsDropdownOpen: () => null,
  defaultTab: NotificationTabs.unreadTab,
  setDefaultTab: () => null,
});

export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [isNotificationsDropdownOpen, setIsNotificationsDropdownOpen] =
    useState(false);
  const [defaultTab, setDefaultTab] = useState(NotificationTabs.unreadTab);

  return (
    <NotificationContext.Provider
      value={{
        isNotificationsDropdownOpen,
        setIsNotificationsDropdownOpen,
        defaultTab,
        setDefaultTab,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
