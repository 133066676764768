const GenericLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Brand/Hub/AppIcon/01_GENERIC</title>
    <defs>
      <path
        d="M12.455098,0.336863542 L12.6484951,0.44007748 L20.3544124,4.84347133 C20.7689029,5.08277725 21.1188115,5.40446379 21.3882778,5.78116826 C21.4540999,5.84648309 21.5116056,5.92116806 21.5598423,6.00455543 C21.6030453,6.07924117 21.63649,6.156892 21.6605706,6.23603114 C21.8512856,6.61947282 21.967553,7.0411703 21.9972138,7.47935397 L22.0047707,7.69971886 L22.0047707,16.5027557 C22.003633,17.611483 21.446433,18.6405424 20.5267967,19.2508681 L20.3501648,19.3603144 L12.6527432,23.7588285 C12.299119,23.9629935 11.9170804,24.0964628 11.5264819,24.1593376 C11.3713525,24.2430968 11.1925,24.291316 11.0023854,24.291316 C10.8127623,24.291316 10.6343432,24.2433458 10.4786015,24.1588788 C10.1599257,24.1087708 9.8462902,24.0102737 9.54967272,23.8644825 L9.35627565,23.7612685 L1.65035838,19.3578747 C0.690171546,18.8035113 0.0765569171,17.8070615 0.00668155031,16.7086843 L1.26121336e-13,16.5016272 L1.26121336e-13,7.69859034 C0.000528986458,7.18310915 0.121256613,6.68484899 0.342367673,6.23809026 C0.367594289,6.15848851 0.401281352,6.08000879 0.444928482,6.00455543 C0.493514615,5.92056396 0.551504486,5.84540143 0.616797632,5.7796288 C0.841623439,5.4665092 1.12190825,5.19118147 1.44874289,4.96974064 L1.65035838,4.84347133 L5.231629,2.79555634 C5.39379522,2.61415345 5.60853426,2.49574153 5.83863896,2.45031065 L9.35202756,0.442517521 C10.3094447,-0.110247531 11.4751457,-0.144795347 12.455098,0.336863542 Z M2.2,8.29 L2.2004765,16.5004986 C2.20083451,16.8495424 2.36652393,17.1748989 2.63919556,17.3790715 L2.74634825,17.4497653 L9.902,21.539 L9.902,12.746 L2.2,8.29 Z M19.804,8.29 L12.102,12.746 L12.102,21.538 L19.254175,17.452205 C19.556456,17.2776832 19.7556924,16.971718 19.7965182,16.6308852 L19.8042942,16.5016272 L19.804,8.29 Z M6.106,4.831 L3.343,6.41 L11.0021791,10.8404185 L13.759,9.245 L6.106,4.831 Z M10.5654632,2.2916666 L10.448018,2.35062661 L8.318,3.567 L15.959,7.973 L18.661,6.41 L11.5525046,2.34818657 C11.2499135,2.17348551 10.8849078,2.15407429 10.5654632,2.2916666 Z"
        id="generic-path-1"
      ></path>
    </defs>
    <g
      id="Brand/Hub/AppIcon/01_GENERIC"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <circle id="Oval" fill="#DFDEDE" cx="24" cy="24" r="24"></circle>
      <g id="Group" transform="translate(8.000000, 8.000000)">
        <g id="package" transform="translate(5.000000, 4.000000)">
          <mask id="generic-mask-2" fill="white">
            <use xlinkHref="#generic-path-1"></use>
          </mask>
          <g id="Mask" fillRule="nonzero"></g>
          <g id="Group" mask="url(#generic-mask-2)" fillOpacity="0.9">
            <g
              transform="translate(-5.000000, -4.000000)"
              id="01_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
            >
              <rect
                id="Rectangle"
                fill="#000000"
                x="0"
                y="0"
                width="32"
                height="32"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default GenericLogo;
