const CatalogAdminLogo = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2596 2.84661L12.49 2.33998V2.84661H11.2596ZM18.1502 18.4252H8.1355C7.984 18.4252 7.861 18.3022 7.861 18.1507V4.52286H18.1502C18.3018 4.52286 18.4247 4.64623 18.4247 4.79773V18.1507C18.4247 18.3022 18.3018 18.4252 18.1502 18.4252ZM6.70187 2.91036C6.38762 3.04086 6.18437 3.34498 6.18437 3.68473V16.1996H2.20075C2.04887 16.1996 1.92587 16.0766 1.92587 15.9251V2.20123C1.92587 2.04973 2.04887 1.92636 2.20075 1.92636H9.09138L6.70187 2.91036ZM18.1502 2.84661H14.1663L14.1666 1.80373C14.1674 0.970482 14.1674 0.970482 14.137 0.869982C14.0388 0.505107 13.7061 0.250107 13.3285 0.250107H2.20075C1.12525 0.250107 0.25 1.12536 0.25 2.20123V15.9251C0.25 17.0006 1.12525 17.8759 2.20075 17.8759H6.18437V18.1507C6.18437 19.2262 7.05963 20.1015 8.1355 20.1015H18.1502C19.2257 20.1015 20.101 19.2262 20.101 18.1507V4.79773C20.101 3.72186 19.2257 2.84661 18.1502 2.84661Z"
      fill="#141414"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2713 9.27774H11.1046V8.44487H10.2713V9.27774ZM11.1627 10.7777H10.2129C9.41785 10.7777 8.77135 10.1312 8.77135 9.33587V8.38674C8.77135 7.59174 9.41785 6.94487 10.2129 6.94487H11.1627C11.9577 6.94487 12.6042 7.59174 12.6042 8.38674V9.33587C12.6042 10.1312 11.9577 10.7777 11.1627 10.7777Z"
      fill="#141414"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6781 9.79896H14.4712C13.9533 9.79896 13.5337 9.37933 13.5337 8.86146C13.5337 8.34358 13.9533 7.92396 14.4712 7.92396H16.6781C17.1959 7.92396 17.6156 8.34358 17.6156 8.86146C17.6156 9.37933 17.1959 9.79896 16.6781 9.79896Z"
      fill="#141414"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2713 14.6841H11.1046V13.8512H10.2713V14.6841ZM11.1627 16.1841H10.2129C9.41785 16.1841 8.77135 15.5376 8.77135 14.7426V13.7931C8.77135 12.9981 9.41785 12.3512 10.2129 12.3512H11.1627C11.9577 12.3512 12.6042 12.9981 12.6042 13.7931V14.7426C12.6042 15.5376 11.9577 16.1841 11.1627 16.1841Z"
      fill="#141414"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6781 15.2054H14.4712C13.9533 15.2054 13.5337 14.7858 13.5337 14.2679C13.5337 13.75 13.9533 13.3304 14.4712 13.3304H16.6781C17.1959 13.3304 17.6156 13.75 17.6156 14.2679C17.6156 14.7858 17.1959 15.2054 16.6781 15.2054Z"
      fill="#141414"
    />
  </svg>
);

export default CatalogAdminLogo;
