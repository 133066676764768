import { useAuthenticationService as authenticationService } from 'admin-portal-shared-services';
import { UserInfoToken } from 'admin-portal-shared-services/dist/services/authentication/AuthenticationService.types';
import { createContext, useContext } from 'react';
import { AppsConfiguration, useEnvContext } from '..';
import {
  CustomizationType,
  hasCustomization,
} from '../../utils/customization/customization';

export type AuthConfig = {
  hasSession: boolean;
  appsWithScope: Array<AppsConfiguration>;
  userName: string;
  token: string;
  decodedToken?: UserInfoToken;
  customization?: CustomizationType | null;
};

const defaultInitialValue: AuthConfig = {
  hasSession: false,
  appsWithScope: [],
  userName: '',
  token: '',
};

const init = (appsConfiguration: AppsConfiguration[]): AuthConfig => {
  const authService = authenticationService();
  const auth = authService.getAuthHeader();
  if (auth) {
    const decodedToken = authService.parseJwt();
    const decodedScopes = decodedToken?.scopes || [];

    const customization = hasCustomization(decodedScopes);
    return {
      hasSession: true,
      appsWithScope: appsConfiguration,
      userName: decodedToken.name || decodedToken.app || '',
      token: auth,
      decodedToken,
      customization,
    };
  } else {
    return defaultInitialValue;
  }
};

export const AuthContext = createContext(defaultInitialValue);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { appsConfiguration } = useEnvContext();
  const session = init(appsConfiguration);
  return (
    <AuthContext.Provider value={session}>{children}</AuthContext.Provider>
  );
};
