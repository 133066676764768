import beesOneLogo from '../../assets/BEES_One.svg';
import taDaIcon from '../../assets/TaDaIcon.svg';
import taDaLogo from '../../assets/TaDaLogo.svg';
import hiveIcon from '../../assets/hive-icon.svg';

type logoProps = {
  src: string;
  alt: string;
  icon?: string;
};

type ILogoProps = {
  tada: logoProps;
  beesOne: logoProps;
};

const currentLogo: ILogoProps = {
  tada: {
    src: taDaLogo,
    icon: taDaIcon,
    alt: 'BEES One TaDa Brand',
  },
  beesOne: {
    src: beesOneLogo,
    icon: hiveIcon,
    alt: 'BEES One Brand',
  },
};

export const getCurrentLogo = (logo: string): logoProps => {
  return currentLogo[logo] || currentLogo['beesOne'];
};
