import { Alert } from '@hexa-ui/components';
import { TypeToast } from 'admin-portal-shared-services';
import React, { useCallback, useEffect } from 'react';
import { Action, ToastType } from './Notification';

import { Container } from './NotificationView.styles';
interface NotificationViewProps {
  message: string;
  type: TypeToast | null;
  id: string;
  open: boolean;
  setNotifications: React.Dispatch<React.SetStateAction<ToastType[]>>;
  actions?: [firstAction: Action, secondAction?: Action];
}

const notificationsFilter = (notifications, id): ToastType[] => {
  const result = notifications.filter((notification) => notification.id !== id);
  return result;
};

export default function NotificationView(
  props: NotificationViewProps,
): JSX.Element {
  const { id, type, message, open, setNotifications, actions } = props;

  const deleteNotification = useCallback(() => {
    setNotifications((oldNotifications: ToastType[]) =>
      notificationsFilter(oldNotifications, id),
    );
  }, [id, setNotifications]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      deleteNotification();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [deleteNotification]);

  return (
    <Container data-state={open ? 'open' : 'closed'}>
      <Alert
        message={message}
        type={type}
        onClose={deleteNotification}
        showCloseButton
        data-testid="notification-view"
        actions={actions}
      />
    </Container>
  );
}
