import { useAuthenticationService } from 'admin-portal-shared-services';
import { ANONYMOUS_ID_KEY } from '../../constants';

export function makeRedirectURL(
  origin: string,
  anonymousId: string | undefined,
): string {
  const logoutRoute = `${origin}/auth/logout`;

  return anonymousId ? `${logoutRoute}?state=${anonymousId}` : logoutRoute;
}

export function useNavigateLogout() {
  const authService = useAuthenticationService();
  const anonymousId = localStorage.getItem(ANONYMOUS_ID_KEY);
  const redirectUrl = makeRedirectURL(window.location.origin, anonymousId);
  return () => {
    authService.unsetLocalStorageKeys([
      'authHeader',
      'refresh_token',
      'authTimestamp',
      'azure_auth',
      'ajs_user_id',
      'TeamStructure_leader',
    ]);
    window.location.href = redirectUrl;
  };
}

export default useNavigateLogout;
