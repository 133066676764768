const BeesGrowLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Brand/Hub/AppIcon/BEESGrowAdmin</title>
    <defs>
      <path
        d="M10.7888972,17.2631579 L6.47411457,17.2631579 L6.47411457,5.81393592 L10.7888972,5.81393592 L10.7888972,17.2631579 Z M4.31478258,11.4746865 L0.539709166,11.4746865 C0.241637309,11.4746865 0,11.7163239 0,12.0144038 L0,16.689044 C0,17.0061168 0.257041099,17.2631579 0.574105821,17.2631579 L4.31478258,17.2631579 L4.31478258,11.4746865 Z M16.7234406,0 L12.9482291,0 L12.9482291,17.2631579 L16.7234406,17.2631579 C17.0215206,17.2631579 17.2631579,17.0215206 17.2631579,16.7234406 L17.2631579,0.539717287 C17.2631579,0.241637309 17.0215206,0 16.7234406,0 Z"
        id="bees-grow-bees-grow-path-1"
      ></path>
    </defs>
    <g
      id="Brand/Hub/AppIcon/BEESGrowAdmin"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="04_Logo/Grow/Black/Icon"
        transform="translate(15.368421, 15.368421)"
      >
        <mask id="bees-grow-bees-grow-mask-2" fill="white">
          <use xlinkHref="#bees-grow-bees-grow-path-1"></use>
        </mask>
        <use
          id="Shape"
          fill="#000000"
          fillRule="nonzero"
          xlinkHref="#bees-grow-bees-grow-path-1"
        ></use>
      </g>
    </g>
  </svg>
);

export default BeesGrowLogo;
