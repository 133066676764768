const CreditManagementLogo = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4966 6.44169C12.4678 6.19302 12.2564 6 12 6C11.7239 6 11.5 6.22386 11.5 6.5V8H10.75L10.6441 8.00245C9.45062 8.05776 8.5 9.04286 8.5 10.25C8.5 11.4926 9.50736 12.5 10.75 12.5H11.5V15H9L8.94169 15.0034C8.69302 15.0322 8.5 15.2436 8.5 15.5C8.5 15.7761 8.72386 16 9 16H11.5V17.5L11.5034 17.5583C11.5322 17.807 11.7436 18 12 18C12.2761 18 12.5 17.7761 12.5 17.5V16H13.25L13.3559 15.9976C14.5494 15.9422 15.5 14.9571 15.5 13.75C15.5 12.5074 14.4926 11.5 13.25 11.5H12.5V9H14.5L14.5583 8.99664C14.807 8.96775 15 8.75642 15 8.5C15 8.22386 14.7761 8 14.5 8H12.5V6.5L12.4966 6.44169ZM11.5 9H10.75C10.0596 9 9.5 9.55964 9.5 10.25C9.5 10.9127 10.0158 11.455 10.6678 11.4973L10.75 11.5H11.5V9ZM12.5 12.5V15H13.25C13.9404 15 14.5 14.4404 14.5 13.75C14.5 13.0873 13.9842 12.545 13.3322 12.5027L13.25 12.5H12.5Z"
      fill="#141414"
    />
  </svg>
);

export default CreditManagementLogo;
