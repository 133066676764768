import appHeader from 'i18n/messages/appHeader';
import MessageMap from '../i18n.d';

const idID: MessageMap = {
  Components: {
    Sidebar: {
      collapseMenu: 'Menu lipat',
    },
  },
  test: {
    translate: 'Menerjemahkan',
  },
  Error500: {
    title: 'Ups! Terjadi kesalahan',
  },
  Error403: {
    title: 'Hmm... Akses ditolak!',
    description:
      'Sepertinya Anda tidak memiliki izin untuk mengakses laman ini.',
    button: 'Beranda',
  },
  Error404: {
    title: 'Hmm... Sepertinya laman ini hilang',
    description: 'Sepertinya laman ini tidak ditemukan atau tidak ada.',
    button: 'Beranda',
  },
  Error503: {
    title: 'Hmm… Layanan tidak tersedia!',
    description:
      'Server kami untuk sementara terlalu sibuk. Anda dapat mencoba lagi dalam beberapa menit, dan laman kemungkinan akan kembali normal.',
    button: 'Coba lagi',
    infoText:
      'Apabila masalah masih berlanjut, anda dapat <a>melaporkan masalah ini.</a>',
  },
  AvatarMenu: {
    signOut: 'Keluar',
    userProfile: 'Profil Pengguna',
  },
  Dashboard: {
    errorPage: 'Tidak ada apa-apa di sini',
    quickLinks: 'Tautan cepat',
    quickLinksLoadingError:
      'Terjadi kesalahan dan kami tidak dapat memuat tautan cepat.',
    reload: 'Muat ulang',
    searchPlaceholder: 'Cari berdasarkan nama alat',
    sortBy: 'Sortir berdasarkan',
    titleAZ: 'Judul A-Z',
    titleZA: 'Judul Z-A',
    noToolFound: 'Tidak ada alat yang ditemukan.',
  },
  Home: {
    greetings: 'Hai, {name}!',
    description:
      'Anda berada di BEES One, platform terpadu dengan berbagai solusi untuk menyelesaikan pekerjaan.',
    dtcDescription:
      'Anda berada di platform terpadu dengan berbagai solusi untuk menyelesaikan pekerjaan.',
    accessReportsCard: 'Dapatkan akses ke laporan terkait kinerja dalam BEES',
    trackDataCard: 'Lacak data dari aplikasi peritel',
    btGuideCard: 'Periksa skor transformasi bisnis Anda',
  },
  Notification: {
    title: 'Pemberitahuan',
    allTab: 'Semua',
    unreadTab: 'Belum dibaca',
    highPriorityTab: 'Prioritas utama',
    markAllRead: 'Tandai semua telah dibaca',
    markUnread: 'Tandai sebagai belum dibaca.',
    markRead: 'Tandai sebagai telah dibaca.',
    highPriority: 'Prioritas utama',
    noNotifications: 'Belum ada pemberitahuan.',
    noUnreadNotifications: 'Tidak ada pemberitahuan yang belum dibaca.',
    noHighPriorityNotifications: 'Belum ada pemberitahuan prioritas utama.',
    noMoreNotifications: 'Tidak ada pemberitahuan lagi untuk ditampilkan.',
    showMore: 'Tampilkan lebih banyak',
    yesterday: 'Kemarin, {value}',
    passwordChanged: 'Kata sandi berhasil diubah!',
    latestNotifications: 'Pemberitahuan terbaru',
    showAll: 'Tampilkan semua pemberitahuan',
    error:
      'Terjadi kesalahan dan kami tidak dapat memuat pemberitahuan terkini.',
    reload: 'Muat ulang',
  },
  AppSwitcher: {
    yourApps: 'Aplikasi anda',
    tools: 'Alat',
    reload: 'Muat Ulang',
    error: 'Terjadi kesalahan dan kami tidak dapat memuat menu.',
  },
  UpdatesPopover: {
    updates: 'PEMBARUAN',
    title: 'Menu baru BEES One',
    description:
      'Sekarang Anda dapat dengan mudah menavigasi antara alat Anda dari menu ini. Cobalah!',
    pagination: '1 dari 1',
    gotIt: 'Mengerti',
  },
  ApprovalFlow: {
    showApprovalFlowButton: 'Approval flow',
  },
  AppCatalog: {
    showToolLibraryButton: 'Tampilkan katalog aplikasi',
  },
  AdminTool: {
    showAdminToolButton: 'Alat admin BEES One',
  },
  TermsAndConditions: {
    showTermsAndConditionsButton: 'Terms & Conditions',
  },
  PrivacyPolicy: {
    showPrivacyPolicyButton: 'Privacy Policy',
  },
  TermsAndPolicies: {
    titleFirstLogin: 'Syarat & Ketentuan dan Kebijakan Privasi',
    titleUpdate: 'Pembaruan Syarat & Ketentuan dan Kebijakan Privasi',
    updateDescriptionLine:
      'Kami telah memperbarui Syarat & Ketentuan dan Kebijakan Privasi.',
    updateDescriptionParagraph1:
      'Untuk melanjutkan, silakan konfirmasi bahwa Anda telah membaca dan memahami Syarat & Ketentuan serta Kebijakan Privasi BEES ONE yang telah diperbarui dengan mencentang kedua kotak di bawah ini.',
    descriptionParagraph1:
      'Untuk melanjutkan, konfirmasi bahwa Anda telah membaca dan memahami Syarat dan Ketentuan serta Kebijakan Privasi BEES ONE dengan mencentang kedua kotak di bawah ini.',
    descriptionParagraph2:
      'Kedua dokumen dapat diklik, dan akan ditampilkan dalam bahasa BEES ONE yang Anda pilih. Anda selalu dapat meninjau kembali ketentuan tersebut dengan menavigasi ke dokumen tertentu di menu pengaturan.',
    agreeTerms: 'Saya telah membaca dan memahami {link} BEES One.',
    agreePolicies: 'Saya telah membaca dan memahami {link} BEES One.',
    terms: 'Syarat & Ketentuan',
    policies: 'Kebijakan Privasi',
    termsLink: 'Syarat & Ketentuan',
    policiesLink: 'Kebijakan Privasi',
    mustCheckBoxError: 'Anda harus mencentang kotak ini untuk melanjutkan.',
    logoutButton: 'Keluar',
    agreeButton: 'Lanjutkan',
  },
  ...appHeader.idID,
};

export default idID;
