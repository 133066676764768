import {
  Button,
  LoadingSpinner,
  TextLink,
  Typography,
} from '@hexa-ui/components2';
import { EmptyState } from '@hexa-ui/illustrations';
import { NotificationTabs } from 'components/Notification/constants';
import { INotification } from 'components/Notification/notification-dropdown/notification-item/NotificationItemTypes';
import { useNotificationContext } from 'contexts';
import { useAnalyticsElementClicked } from 'hooks/useAnalyticsElementClicked';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  ElementLocationType,
  ElementType,
  PageName,
} from '../../../../../analytics';
import { useLoadNotifications } from '../../../../components/Notification/services/notification/newLoadNotification/newLoadNotificationService';
import NotificationItem from '../NotificationItem/NotificationItem';
import {
  Card,
  Container,
  EmptyStateText,
  List,
} from './LatestNotifications.styles';

export function LatestNotifications() {
  const { formatMessage } = useIntl();
  const { setIsNotificationsDropdownOpen, setDefaultTab } =
    useNotificationContext();
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const analyticsElementClicked = useAnalyticsElementClicked();

  const {
    notifications: latestNotifications,
    isLoading,
    hasError,
    fetchNotifications,
  } = useLoadNotifications({ pageNumber: 0, pageSize: 10 });

  useEffect(() => {
    if (latestNotifications) {
      setNotifications(latestNotifications);
    }

    return () => {
      setNotifications([]);
    };
  }, [latestNotifications]);

  const onReload = () => {
    fetchNotifications();

    analyticsElementClicked.track({
      isCoreEvent: false,
      elementLocationType: ElementLocationType.Page,
      elementLocationName: 'LATEST_NOTIFICATIONS',
      elementLabel: 'Reload',
      elementName: 'LatestNotifications_ReloadButton',
      elementType: ElementType.Button,
      pageLabel: 'Home',
      pageName: PageName.Home,
    });
  };

  const showAllNotifications = () => {
    setDefaultTab(NotificationTabs.allTab);
    setIsNotificationsDropdownOpen(true);

    analyticsElementClicked.track({
      isCoreEvent: false,
      elementLocationType: ElementLocationType.Page,
      elementLocationName: 'LATEST_NOTIFICATIONS',
      elementLabel: 'Show all notifications',
      elementName: 'LatestNotifications_ShowAllButton',
      elementType: ElementType.Button,
      pageLabel: 'Home',
      pageName: PageName.Home,
    });
  };

  const updateNotifications = (notification: INotification) => {
    setNotifications((prev) => {
      return prev.map((prevNotification) => {
        if (notification.id === prevNotification.id) {
          return { ...prevNotification, read: notification.read };
        }
        return prevNotification;
      });
    });
  };

  const renderNotifications = () => {
    if (isLoading) {
      return (
        <Container>
          <LoadingSpinner size="xlarge" />
        </Container>
      );
    }
    if (hasError) {
      return (
        <Container style={{ gap: '16px' }}>
          <EmptyState.SomethingWentWrong width="128px" height="128px" />
          <EmptyStateText>
            {formatMessage({ id: 'Notification.error' })}
          </EmptyStateText>
          <Button variant="secondary" onClick={onReload}>
            {formatMessage({ id: 'Notification.reload' })}
          </Button>
        </Container>
      );
    }
    if (notifications?.length === 0) {
      return (
        <Container style={{ gap: '16px' }}>
          <EmptyState.NoDataYet width="128px" height="128px" />
          <EmptyStateText>
            {formatMessage({ id: 'Notification.noNotifications' })}
          </EmptyStateText>
        </Container>
      );
    }
    return (
      <List>
        {notifications?.map((notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            updateNotifications={updateNotifications}
          />
        ))}
      </List>
    );
  };

  return (
    <Card elevated="minimal" border="medium">
      <Typography.Header
        variant="h4"
        style={{ margin: '0px', padding: '0px 24px', lineHeight: '40px' }}
      >
        {formatMessage({ id: 'Notification.latestNotifications' })}
      </Typography.Header>
      {renderNotifications()}
      {notifications?.length > 0 && (
        <TextLink
          style={{
            padding: '16px 24px 0px',
            position: 'absolute',
            bottom: '20px',
          }}
          onClick={showAllNotifications}
        >
          {formatMessage({ id: 'Notification.showAll' })}
        </TextLink>
      )}
    </Card>
  );
}

export default LatestNotifications;
