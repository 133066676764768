import axios from 'Api/Api';
import { GetAppsResponse, GetCategoriesOptions, Tool } from 'models/Tool';
import { CATALOG_SERVICE } from '../../constants';

class AppCatalogService {
  getCategoriesWithApps(options: GetCategoriesOptions) {
    return axios.get<Tool[]>(`${CATALOG_SERVICE}/categories`, {
      params: options.params,
      headers: {
        'accept-language': options.locale,
      },
    });
  }

  getApps(locale: string) {
    return axios.get<GetAppsResponse>(`${CATALOG_SERVICE}/apps`, {
      params: {
        pageSize: 1000,
      },
      headers: {
        'accept-language': locale,
      },
    });
  }
}

export const appCatalogService = new AppCatalogService();
