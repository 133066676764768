const ANONYMOUS_ID_KEY = 'ajs_anonymous_id';
const TOPBAR_HEIGHT = '3.5rem';
const CATALOG_SERVICE = '/api/bees-hub-app-catalog-service';
const ANALYTICS_BUSINESS_SERVICE = '/api/partner-analytics-business-service';

export {
  ANONYMOUS_ID_KEY,
  CATALOG_SERVICE,
  TOPBAR_HEIGHT,
  ANALYTICS_BUSINESS_SERVICE,
};
