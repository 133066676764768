import Header from 'components/Header/Header';
import TermsAndPoliciesModal from 'components/TermsAndPoliciesModal/TermsAndPoliciesModal';
import { globalStyles } from 'core/styled/GlobalStyleReset';
import { Outlet as RouterOutlet } from 'react-router-dom';
import {
  useAnalyticsPageViewed,
  usePasswordResetSuccessNotification,
} from '../../hooks';
import Notification from './components/Notification/Notification';

export const Outlet = () => {
  usePasswordResetSuccessNotification();
  useAnalyticsPageViewed();

  globalStyles();

  return (
    <>
      <Header />
      <Notification />
      <TermsAndPoliciesModal />
      <RouterOutlet />
    </>
  );
};
