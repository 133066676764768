const BeesLinkAdminLogo = () => (
  <svg
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>D8EECF66-D965-4E81-B3DA-78625F1D97DD</title>
    <defs>
      <path
        d="M46,32 C47.1045695,32 48,32.8954305 48,34 L48,38 C47.9933878,43.5201063 43.5201063,47.9933878 38,48 L34,48 C32.8954305,48 32,47.1045695 32,46 C32,44.8954305 32.8954305,44 34,44 L38,44 C41.3137085,44 44,41.3137085 44,38 L44,34 C44,32.8954305 44.8954305,32 46,32 Z M2,32 C3.1045695,32 4,32.8954305 4,34 L4,38 C4,41.3137085 6.6862915,44 10,44 L14,44 C15.1045695,44 16,44.8954305 16,46 C16,47.1045695 15.1045695,48 14,48 L10,48 C4.4798937,47.9933878 0.00661223267,43.5201063 0,38 L0,34 C0,32.8954305 0.8954305,32 2,32 Z M26,26 C32.6246751,26.0066148 37.9933852,31.3753249 38,38 C38,39.1045695 37.1045695,40 36,40 C34.8954305,40 34,39.1045695 34,38 C34,33.581722 30.418278,30 26,30 L22,30 C17.581722,30 14,33.581722 14,38 C14,39.1045695 13.1045695,40 12,40 C10.8954305,40 10,39.1045695 10,38 C10.0066148,31.3753249 15.3753249,26.0066148 22,26 L26,26 Z M2,16 C3.1045695,16 4,15.1045695 4,14 L4,10 C4,6.6862915 6.6862915,4 10,4 L14,4 C15.1045695,4 16,3.10456948 16,2 C16,0.895430517 15.1045695,0 14,0 L10,0 C4.4798937,0.00661223267 0.00661223267,4.4798937 0,10 L0,14 C0,15.1045695 0.8954305,16 2,16 Z M24,6 C28.418278,6 32,9.581722 32,14 C32,18.418278 28.418278,22 24,22 C19.581722,22 16,18.418278 16,14 C16,9.581722 19.581722,6 24,6 Z M24,10 C21.790861,10 20,11.790861 20,14 C20,16.209139 21.790861,18 24,18 C26.209139,18 28,16.209139 28,14 C28,11.790861 26.209139,10 24,10 Z M38,0 C43.5201063,0.00661223267 47.9933878,4.4798937 48,10 L48,14 C48,15.1045695 47.1045695,16 46,16 C44.8954305,16 44,15.1045695 44,14 L44,10 C44,6.6862915 41.3137085,4 38,4 L34,4 C32.8954305,4 32,3.1045695 32,2 C32,0.8954305 32.8954305,0 34,0 Z"
        id="bees-link-admin-path"
      ></path>
    </defs>
    <g
      id="___Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="01_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
        transform="translate(8.000000, 8.000000)"
      >
        <mask id="bees-link-admin-mask" fill="white">
          <use xlinkHref="#bees-link-admin-path"></use>
        </mask>
        <g id="Mask" fillRule="nonzero"></g>
        <g
          mask="url(#bees-link-admin-mask)"
          fill="#000000"
          fillOpacity="0.9"
          id="Rectangle"
        >
          <g transform="translate(-8.000000, -8.000000)">
            <rect x="0" y="0" width="64" height="64"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BeesLinkAdminLogo;
