import { styled } from '@hexa-ui/theme';
import tokens from '@hexa-ui/tokens/dist/json/nested.json';
import { Content, Item, Portal } from '@radix-ui/react-select';
import { breakpoints } from 'utils/constants';

export const SelectPopover = styled(Portal, {
  zIndex: 5000,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  boxShadow: tokens.shadow.elevation[1],
  borderRadius: '8px',
  backgroundColor: 'white',
  maxHeight: '168px',
  width: '196px',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(219, 218, 218, 1) transparent',

  '&::-webkit-scrollbar': {
    width: '12px',
  },

  '&::-webkit-scrollbar-track': {
    background: 'transparent',
    borderRadius: '8px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(219, 218, 218, 1)',
    borderRadius: '8px',
  },
});

export const SelectContent = styled(Content, {
  marginTop: '8px',
});

export const SelectOption = styled(Item, {
  all: 'unset',
  display: 'flex',
  justifyContent: 'start',
  cursor: 'pointer',
  padding: '16px',
  color: tokens.color.foundation.txt.default,
  fontFamily: 'Work Sans',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  '&:hover': {
    backgroundColor: tokens.color.component.bg['dropdown-menu'].item.hover,
    fontWeight: 500,
  },
});

export const SelectValue = styled('span', {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '112px',
  fontSize: '14px',
  lineHeight: '16px',
  variants: {
    variant: {
      country: {
        [`@media (min-width: ${breakpoints.mobileLarge})`]: {
          width: '94px',
        },
      },
      vendor: {},
    },
  },
});
