const DeliveryWindowLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Brand/Hub/AppIcon/DeliveryWindow</title>
    <defs>
      <path
        d="M16,0 C16.5522847,0 17,0.44771525 17,1 L17,1 L17,5 L20,5 C20.2210137,5 20.434484,5.07316447 20.6080295,5.20608063 L20.7071068,5.29289322 L23.7071068,8.29289322 C23.8946432,8.4804296 24,8.73478351 24,9 L24,9 L24,14 C24,14.5522847 23.5522847,15 23,15 L23,15 L21.6631686,14.9999667 C21.8791486,15.4546029 22,15.9631883 22,16.5 C22,18.4329966 20.4329966,20 18.5,20 C16.5670034,20 15,18.4329966 15,16.5 C15,15.9631883 15.1208514,15.4546029 15.3368314,14.9999667 L8.66316861,14.9999667 C8.87914858,15.4546029 9,15.9631883 9,16.5 C9,18.4329966 7.43299662,20 5.5,20 C3.56700338,20 2,18.4329966 2,16.5 C2,15.9631883 2.12085142,15.4546029 2.33683139,14.9999667 L1,15 C0.44771525,15 0,14.5522847 0,14 L0,14 L0,1 C0,0.44771525 0.44771525,0 1,0 L1,0 Z M5.5,15 C4.67157288,15 4,15.6715729 4,16.5 C4,17.3284271 4.67157288,18 5.5,18 C6.32842712,18 7,17.3284271 7,16.5 C7,15.6715729 6.32842712,15 5.5,15 Z M18.5,15 C17.6715729,15 17,15.6715729 17,16.5 C17,17.3284271 17.6715729,18 18.5,18 C19.3284271,18 20,17.3284271 20,16.5 C20,15.6715729 19.3284271,15 18.5,15 Z M15,2 L2,2 L2,13 L15,13 L15,2 Z M19.585,7 L17,7 L17,13 L22,13 L22,9.415 L19.585,7 Z"
        id="delivery-window-path-1"
      ></path>
    </defs>
    <g
      id="Brand/Hub/AppIcon/DeliveryWindow"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group" transform="translate(8.000000, 8.000000)">
        <g
          id="ZZ_Color-Override/Black"
          transform="translate(4.000000, 6.000000)"
        >
          <mask id="delivery-window-mask-2" fill="white">
            <use xlinkHref="#delivery-window-path-1"></use>
          </mask>
          <g id="Mask" fillRule="nonzero"></g>
          <g id="Group" mask="url(#delivery-window-mask-2)" fillOpacity="0.9">
            <g
              transform="translate(-4.615385, -6.666667)"
              id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
            >
              <rect
                id="Rectangle"
                fill="#000000"
                x="0"
                y="0"
                width="32.8205128"
                height="32.8205128"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DeliveryWindowLogo;
