/* eslint-disable react-hooks/exhaustive-deps */
import {
  Heading,
  IconButton,
  LoadingDots,
  SearchField,
} from '@hexa-ui/components';
import {
  Button,
  DropdownButton,
  TextLink,
  Typography,
} from '@hexa-ui/components2';
import { Search } from '@hexa-ui/icons';
import { Sort } from '@hexa-ui/icons2';
import { EmptyState } from '@hexa-ui/illustrations';
import { useAuthenticationService } from 'admin-portal-shared-services';
import { AppCard } from 'components';
import { useAuthContext } from 'contexts';
import { AppMenuItem, useGenerateAppMenuItems } from 'hooks';
import { useAnalyticsElementClicked } from 'hooks/useAnalyticsElementClicked';
import { useGetApps } from 'hooks/useGetApps';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { App } from 'models/Tool';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  ElementLocationType,
  ElementType,
  PageName,
} from '../../../../../analytics';
import { DropdownMenu } from '../DropdownMenu';
import {
  AppsGrid,
  AppsWrapper,
  Card,
  ExceptionContainer,
  FilterWrapper,
  Header,
  LoadingContainer,
  SearchContainer,
  SearchFieldWrapper,
  StyledFilterLabel,
} from './QuickLinks.styles';

const MIN_SEARCH_LENGTH = 3;

export const QuickLinks = (): JSX.Element => {
  const configApps: AppMenuItem[] = useGenerateAppMenuItems();
  const { formatMessage, locale } = useIntl();
  const navigate = useNavigate();
  const analyticsElementClicked = useAnalyticsElementClicked();
  const { appsWithScope } = useAuthContext();
  const { data, isLoading, isError, refetch } = useGetApps(locale);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [sortOrder, setSortOrder] = useState<'titleAZ' | 'titleZA'>('titleAZ');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const filterMenuRef = useRef<HTMLDivElement>(null);
  const dropdownButtonRef = useRef(null);
  const authService = useAuthenticationService();
  const userIsFederated = authService.getIsFederated();

  const hasAccessToToolLibrary =
    configApps?.some((app) => app.appId === 'BEES_HUB_APP_CATALOG') &&
    userIsFederated;

  const allowedApps = useMemo(
    () =>
      data?.data?.content?.filter((app) =>
        appsWithScope.some(
          (allowedApp) =>
            allowedApp.id === app.identifier && !allowedApp.hidden,
        ),
      ) || [],
    [data, appsWithScope],
  );

  const sortApps = (apps: App[], order: 'titleAZ' | 'titleZA') => {
    if (!apps) return [];

    return apps.sort((a, b) => {
      if (order === 'titleAZ') {
        return a.name?.localeCompare(b.name);
      } else {
        return b.name?.localeCompare(a.name);
      }
    });
  };

  const sortedAllowedApps = useMemo(
    () => sortApps([...allowedApps], sortOrder),
    [allowedApps, sortOrder],
  );

  const filteredApps = useMemo(() => {
    if (searchValue.length >= MIN_SEARCH_LENGTH) {
      return sortApps(
        allowedApps?.filter((app) =>
          app.name.toLowerCase().includes(searchValue.toLowerCase()),
        ) || [],
        sortOrder,
      );
    }

    return sortedAllowedApps;
  }, [searchValue, allowedApps, sortedAllowedApps, sortOrder]);

  const [apps, setApps] = useState<App[]>(filteredApps || []);

  useEffect(() => {
    setApps(filteredApps);
  }, [filteredApps]);

  const handleOpenSearch = () => {
    analyticsElementClicked.track({
      isCoreEvent: false,
      elementLocationType: ElementLocationType.Page,
      elementLocationName: 'QUICK_LINKS',
      elementLabel: 'Filter',
      elementName: 'QuickLinks_MagnifyingGlassButton',
      elementType: ElementType.Button,
      pageName: PageName.Home,
      pageLabel: 'Home',
    });

    setIsSearchOpen((prevState) => !prevState);
  };

  const handleChangeSearchTool = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchValue(event.target.value);
  };

  const handleClear = () => {
    setSearchValue('');
    setApps(allowedApps);
  };

  const sentSortAnalytics = (sortOrder: 'titleAZ' | 'titleZA') => {
    analyticsElementClicked.track({
      isCoreEvent: false,
      elementLocationType: ElementLocationType.Menu,
      elementLocationName: 'QUICK_LINKS',
      elementLabel: sortOrder === 'titleAZ' ? 'Title A-Z' : 'Title Z-A',
      elementName:
        sortOrder === 'titleAZ'
          ? 'QuickLinks_titleAzMenuOption'
          : 'QuickLinks_titleZaMenuOption',
      elementType: ElementType.MenuOption,
      pageName: PageName.Home,
      pageLabel: 'Home',
    });
  };

  const handleSortAZ = () => {
    setSortOrder('titleAZ');
    sentSortAnalytics('titleAZ');
  };

  const handleSortZA = () => {
    setSortOrder('titleZA');
    sentSortAnalytics('titleZA');
  };

  const filterMenuOptions = [
    {
      id: 1,
      groupName: formatMessage({ id: 'Dashboard.sortBy' }),
      options: [
        {
          value: 'titleAZ',
          label: formatMessage({ id: 'Dashboard.titleAZ' }),
          onClick: handleSortAZ,
        },
        {
          value: 'titleZA',
          label: formatMessage({ id: 'Dashboard.titleZA' }),
          onClick: handleSortZA,
        },
      ],
    },
  ];

  const handleCloseFilter = () => {
    setIsFilterOpen(false);
  };

  useOnClickOutside(filterMenuRef, handleCloseFilter, [dropdownButtonRef]);

  const onRefetch = () => {
    analyticsElementClicked.track({
      isCoreEvent: false,
      elementLocationType: ElementLocationType.Page,
      elementLocationName: 'QUICK_LINKS',
      elementLabel: 'Reload',
      elementName: 'QuickLinks_ReloadButton',
      elementType: ElementType.Button,
      pageName: PageName.Home,
      pageLabel: 'Home',
    });
    refetch();
  };

  const onChangeRoute = (app: App) => {
    analyticsElementClicked.track({
      isCoreEvent: true,
      elementLocationType: ElementLocationType.Page,
      elementLocationName: 'QUICK_LINKS',
      elementLabel: app.defaultAppName,
      elementName: 'QuickLinks_AppButton',
      elementType: ElementType.Button,
      pageName: PageName.Home,
      pageLabel: 'Home',
      userPermissionGroup: appsWithScope?.find(
        (appWithScope) => appWithScope.id === app.identifier,
      )?.authorizationApp,
    });
  };

  const onNavigateToToolLibrary = () => {
    analyticsElementClicked.track({
      isCoreEvent: false,
      elementLocationType: ElementLocationType.Page,
      elementLocationName: 'QUICK_LINKS',
      elementLabel: 'Show tool library',
      elementName: 'QuickLinks_showToolLibraryLink',
      elementType: ElementType.Link,
      pageName: PageName.Home,
      pageLabel: 'Home',
      userPermissionGroup: appsWithScope?.find(
        (appWithScope) => appWithScope.id === 'BEES_HUB_APP_CATALOG',
      )?.authorizationApp,
    });
    navigate('/tool-library');
  };

  const renderApps = () => {
    if (isLoading) {
      return (
        <LoadingContainer>
          <LoadingDots data-testid="loading-dots" size="xxlarge" />
        </LoadingContainer>
      );
    }

    if (isError) {
      return (
        <ExceptionContainer style={{ padding: '8px 0' }}>
          <EmptyState.SomethingWentWrong width={128} height={128} />
          <Typography.Body
            style={{ textAlign: 'center', margin: 0, maxWidth: '227px' }}
          >
            {formatMessage({ id: 'Dashboard.quickLinksLoadingError' })}
          </Typography.Body>
          <Button variant="secondary" onClick={onRefetch}>
            {formatMessage({ id: 'Dashboard.reload' })}
          </Button>
        </ExceptionContainer>
      );
    }

    if (searchValue && apps.length === 0) {
      return (
        <ExceptionContainer style={{ padding: '46px 0' }}>
          <EmptyState.NoSearchResults width={128} height={128} />
          <Typography.Body style={{ textAlign: 'center', margin: 0 }}>
            {formatMessage({ id: 'Dashboard.noToolFound' })}
          </Typography.Body>
        </ExceptionContainer>
      );
    }

    if (allowedApps?.length === 0) {
      return (
        <ExceptionContainer style={{ padding: '46px 0' }}>
          <EmptyState.NoDataYet width={128} height={128} />
          <Typography.Body style={{ textAlign: 'center', margin: 0 }}>
            {formatMessage({ id: 'Dashboard.noQuickLinks' })}
          </Typography.Body>
        </ExceptionContainer>
      );
    }

    return (
      <AppsWrapper>
        <AppsGrid>
          {apps?.map((app) => (
            <AppCard
              appName={app.name}
              appRoute={app.appRoute}
              iconBackgroundColor={app.iconColor}
              icon={app.icon}
              key={app.identifier}
              onChangeRoute={() => onChangeRoute(app)}
            />
          ))}
        </AppsGrid>
      </AppsWrapper>
    );
  };

  return (
    <Card elevated="minimal" border="medium" isSearchOpen={isSearchOpen}>
      <Header>
        <Heading size="H4" style={{ lineHeight: '40px' }}>
          {formatMessage({ id: 'Dashboard.quickLinks' })}
        </Heading>
        {allowedApps.length > 0 && (
          <IconButton
            icon={() => <Search />}
            size="medium"
            variant={isSearchOpen ? 'primary' : 'secondary'}
            onClick={handleOpenSearch}
            disabled={!!searchValue}
            data-testid="search-button"
          />
        )}
      </Header>

      {isSearchOpen && (
        <SearchContainer>
          <FilterWrapper>
            <DropdownButton
              icon={Sort}
              size="medium"
              open={isFilterOpen}
              onClick={() => setIsFilterOpen((open) => !open)}
              ref={dropdownButtonRef}
              data-testid="filter-dropdown-button"
              style={{ maxHeight: '40px', boxSizing: 'border-box' }}
            >
              <StyledFilterLabel variant="large" style={{ fontWeight: 500 }}>
                {formatMessage({ id: `Dashboard.${sortOrder}` })}
              </StyledFilterLabel>
            </DropdownButton>
            {isFilterOpen && (
              <DropdownMenu
                menuOptions={filterMenuOptions}
                data-testid="filter-dropdown-menu"
                handleClose={handleCloseFilter}
                ref={filterMenuRef}
              />
            )}
          </FilterWrapper>
          <SearchFieldWrapper>
            <SearchField.Root
              placeholder={formatMessage({
                id: 'Dashboard.searchPlaceholder',
              })}
              data-testid="tools-searcher"
              onChange={handleChangeSearchTool}
              value={searchValue}
              onClear={handleClear}
            />
          </SearchFieldWrapper>
        </SearchContainer>
      )}
      {renderApps()}
      {hasAccessToToolLibrary && (
        <TextLink
          onClick={onNavigateToToolLibrary}
          style={{ margin: '16px 0 0 24px', width: 'max-content' }}
        >
          {formatMessage({ id: 'AppCatalog.showToolLibraryButton' })}
        </TextLink>
      )}
    </Card>
  );
};
