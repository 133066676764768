const IdentityManagementLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Brand/Hub/AppIcon/IdentityManagement</title>
    <defs>
      <path
        d="M23,16 C23.5128358,16 23.9355072,16.3860402 23.9932723,16.8833789 L24,17 L24,19 C23.9967809,21.6874202 21.8762245,23.8786135 19.2167803,23.9951303 L19,24 L17,24 C16.4477153,24 16,23.5522847 16,23 C16,22.4871642 16.3860402,22.0644928 16.8833789,22.0067277 L17,22 L19,22 C20.5976809,22 21.9036609,20.75108 21.9949073,19.1762728 L22,19 L22,17 C22,16.4477153 22.4477153,16 23,16 Z M1,16 C1.55228475,16 2,16.4477153 2,17 L2,17 L2,19 C2,20.6568542 3.34314575,22 5,22 L5,22 L7,22 C7.55228475,22 8,22.4477153 8,23 C8,23.5522847 7.55228475,24 7,24 L7,24 L5,24 C2.23994685,23.9966939 0.00330611633,21.7600532 0,19 L0,19 L0,17 C0,16.4477153 0.44771525,16 1,16 Z M13,13 C16.3123376,13.0033074 18.9966926,15.6876624 19,19 C19,19.5522847 18.5522847,20 18,20 C17.4477153,20 17,19.5522847 17,19 C17,16.8578046 15.3160315,15.1089211 13.1996403,15.0048953 L13,15 L11,15 C8.790861,15 7,16.790861 7,19 C7,19.5522847 6.55228475,20 6,20 C5.44771525,20 5,19.5522847 5,19 C5.00323221,15.7629428 7.56703113,13.1256479 10.775156,13.0043559 L11,13 L13,13 Z M1,8 C1.55228475,8 2,7.55228475 2,7 L2,5 C2,3.34314575 3.34314575,2 5,2 L7,2 C7.55228474,2 8,1.55228474 8,1 C8,0.447715258 7.55228474,0 7,0 L5,0 C2.23994685,0.00330611633 0.00330611633,2.23994685 0,5 L0,7 C0,7.55228475 0.44771525,8 1,8 Z M19,0 L17,0 C16.4477153,0 16,0.44771525 16,1 C16,1.55228475 16.4477153,2 17,2 L19,2 C20.6568542,2 22,3.34314575 22,5 L22,7 C22,7.55228475 22.4477153,8 23,8 C23.5522847,8 24,7.55228475 24,7 L24,5 C23.9966939,2.23994685 21.7600532,0.00330611633 19,0 Z M12,11 C14.209139,11 16,9.209139 16,7 C16,4.790861 14.209139,3 12,3 C9.790861,3 8,4.790861 8,7 C8,9.209139 9.790861,11 12,11 Z M12,5 C13.1045695,5 14,5.8954305 14,7 C14,8.1045695 13.1045695,9 12,9 C10.8954305,9 10,8.1045695 10,7 C10,5.8954305 10.8954305,5 12,5 Z"
        id="identity-management-path-1"
      ></path>
    </defs>
    <g
      id="Brand/Hub/AppIcon/IdentityManagement"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group" transform="translate(8.000000, 8.000000)">
        <g
          id="01_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
          transform="translate(4.000000, 4.000000)"
        >
          <mask id="identity-management-mask-2" fill="white">
            <use xlinkHref="#identity-management-path-1"></use>
          </mask>
          <g id="Mask" fillRule="nonzero"></g>
          <g
            id="Group"
            mask="url(#identity-management-mask-2)"
            fillOpacity="0.9"
          >
            <g
              transform="translate(-4.000000, -4.000000)"
              id="01_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
            >
              <rect
                id="Rectangle"
                fill="#000000"
                x="0"
                y="0"
                width="32"
                height="32"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IdentityManagementLogo;
