const enUS = {
  'inner.header': {
    'select.vendor.placeholder': 'Select a vendor',
    'select.vendor.placeholder.disabled': 'No vendor available',
    'select.country.placeholder': 'Select a country',
    'select.country.placeholder.disabled': 'No country available',
    'select.vendor.placeholder.loading': 'Loading vendors',
  },
};

const es419 = {
  'inner.header': {
    'select.vendor.placeholder': 'Seleccione un proveedor',
    'select.vendor.placeholder.disabled': 'No hay proveedores disponibles',
    'select.country.placeholder': 'Seleccione un país',
    'select.country.placeholder.disabled': 'No hay países disponibles',
    'select.vendor.placeholder.loading': 'Buscando proveedores',
  },
};

const ptBR = {
  'inner.header': {
    'select.vendor.placeholder': 'Selecione um fornecedor',
    'select.vendor.placeholder.disabled': 'Nenhum fornecedor disponível',
    'select.country.placeholder': 'Selecione um país',
    'select.country.placeholder.disabled': 'Nenhum país disponível',
    'select.vendor.placeholder.loading': 'Buscando fornecedores',
  },
};

const idID = {
  'inner.header': {
    'select.vendor.placeholder': 'Pilih vendor',
    'select.vendor.placeholder.disabled': 'Tidak ada vendor yang tersedia',
    'select.country.placeholder': 'Pilih negara',
    'select.country.placeholder.disabled': 'Tidak ada negara yang tersedia',
    'select.vendor.placeholder.loading': 'Memuat vendor',
  },
};

export default {
  enUS,
  es419,
  ptBR,
  idID,
};
