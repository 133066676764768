import { useEffect } from 'react';

declare global {
  interface Window {
    newrelic: {
      setCustomAttribute: typeof newrelic.setCustomAttribute;
    };
  }
}

export function useNewRelic() {
  const setMfeNameAttribute = (evt) => {
    if (!window.newrelic || !evt.detail.appsByNewStatus) {
      return;
    }

    const mounted = evt.detail.appsByNewStatus.MOUNTED || [];
    if (!mounted.length) {
      return;
    }

    const valid = mounted.filter((mfe) => mfe !== '@admin-portal/main-mfe');
    window.newrelic.setCustomAttribute('mfeName', valid[0]);
  };

  useEffect(() => {
    window.addEventListener(
      'single-spa:before-routing-event',
      setMfeNameAttribute,
    );
    window.addEventListener('single-spa:routing-event', setMfeNameAttribute);

    return () => {
      window.removeEventListener(
        'single-spa:before-routing-event',
        setMfeNameAttribute,
      );
      window.removeEventListener(
        'single-spa:routing-event',
        setMfeNameAttribute,
      );
    };
  }, []);
}
