import { useLanguageContext } from 'contexts';
import { ReactNode } from 'react';
import { IntlProvider as NativeProvider } from 'react-intl';
import enUS from './languages/en-US';
import es419 from './languages/es-419';
import idID from './languages/id-ID';
import koKR from './languages/ko-KR';
import ptBR from './languages/pt-BR';

const DEFAULT_LANGUAGE = 'en-US';
type IIntlProvider = {
  children: ReactNode;
};

export type MessageMap = {
  [key: string]: { [key: string]: string | MessageMap } | string;
};

export const messages: { [language: string]: MessageMap } = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-419': es419,
  'ko-KR': koKR,
  'id-ID': idID,
};

export function IntlProvider({ children }: IIntlProvider): JSX.Element {
  const { preferredLanguage, defaultLanguage } = useLanguageContext();

  const mergedMessages = mergeMessages(
    messages,
    preferredLanguage || defaultLanguage,
  );
  return (
    <NativeProvider
      locale={preferredLanguage || defaultLanguage}
      defaultLocale={defaultLanguage}
      messages={mergedMessages}
    >
      {children}
    </NativeProvider>
  );
}

export const flattenObject = (
  ob: Record<string, string> | string | MessageMap,
): Record<string, string> => {
  const toReturn: Record<string, string> = {};

  for (const i in ob as Record<string, string>) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      for (const x in flatObject) {
        (toReturn[`${i}.${x}`] as unknown) = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const mergeMessages = (
  messagesInput: MessageMap,
  selectedLocale: string,
): Record<string, string> => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);
  return { ...defaultMessages, ...localeMessages };
};

export default IntlProvider;
