import { Heading, Tabs, TextButton } from '@hexa-ui/components';
import { Checkmark2 } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import { useNotificationContext } from 'contexts';
import { useStore } from 'effector-react';
import { useIntl } from 'react-intl';
import { NotificationTabs } from '../constants';
import { execute as LoadNotificationService } from '../services/notification/loadNotification/loadNotificationService';
import { execute as readNotification } from '../services/notification/read/readNotificationService';
import NotificationContentStore from '../store/content/NotificationContentStore';
import NotificationItem from './notification-item/NotificationItem';

const NotificationDropdownView = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { notifications } = useStore(NotificationContentStore);
  const { defaultTab  } = useNotificationContext()

  const handleReadAllNotifications = async () => {
    const notificationIds: string[] = notifications
      .filter((notification) => !notification.read)
      .map((notification) => notification.id);
    await readNotification({ notificationIds, notifications });
  }

  return (
    <StyledCard data-testid="notification-dropdown-view">
      <StyledTitleContainer>
        <Heading size="H4">{formatMessage({ id: 'Notification.title' })}</Heading>
        <TextButton
          iconPosition="leading"
          icon={Checkmark2}
          onClick={handleReadAllNotifications}
          css={{ fontSize: '$2', fontWeight: '$normal', lineHeight: '$3' }}
        >
          {formatMessage({ id: 'Notification.markAllRead' })}
        </TextButton>
      </StyledTitleContainer>
      <Tabs.Root defaultValue={defaultTab}>
        <Tabs.List>
          <Tabs.Trigger
            value={NotificationTabs.unreadTab}
            css={{ color: '#757575' }}
            onClick={() => LoadNotificationService({ unread: true, priority: false, pageNumber: 0, pageSize: 10 })}
            data-testid="unread-tab-trigger"
          >
            {formatMessage({ id: 'Notification.unreadTab' })}
          </Tabs.Trigger>
          <Tabs.Trigger
            value={NotificationTabs.highPriorityTab}
            css={{ color: '#757575' }}
            onClick={() => LoadNotificationService({ unread: false, priority: true, pageNumber: 0, pageSize: 10 })}
            data-testid="priority-tab-trigger"
          >
            {formatMessage({ id: 'Notification.highPriorityTab' })}
          </Tabs.Trigger>
          <Tabs.Trigger
            value={NotificationTabs.allTab}
            css={{ color: '#757575' }}
            onClick={() => LoadNotificationService({ unread: false, priority: false, pageNumber: 0, pageSize: 10 })}
            data-testid="all-tab-trigger"
          >
            {formatMessage({ id: 'Notification.allTab' })}
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content
          value={NotificationTabs.unreadTab}
          css={{ padding: '0', height: '100%' }}
          data-testid="unread-tab-content"
        >
          <NotificationItem isUnread />
        </Tabs.Content>
        <Tabs.Content
          value={NotificationTabs.highPriorityTab}
          css={{ padding: '0', height: '100%' }}
          data-testid="priority-tab-content"
        >
          <NotificationItem isHighPriority />
        </Tabs.Content>
        <Tabs.Content
          value={NotificationTabs.allTab}
          css={{ padding: '0', height: '100%' }}
          data-testid="all-tab-content"
        >
          <NotificationItem />
        </Tabs.Content>
      </Tabs.Root>
    </StyledCard>
  );
};

const StyledCard = styled('section', {
  height: 'calc(100vh - 56px)',
  width: '28rem',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 6px 16px 0px #00000029',
  position: 'fixed',
  right: '0',
  top: '56px',
  zIndex: '2',
  '> div': {
    height: 'calc(100vh - 112px)',
  },
});

const StyledTitleContainer = styled('header', {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '$4',
});

export default NotificationDropdownView;
