import { AppsConfiguration, useAuthContext } from 'contexts';
import { useIntl } from 'react-intl';

export type AppMenuItem = {
  appName: string;
  appDescription: string;
  appId: string;
  homeRoute: string;
  baseRoute: string;
  hidden: boolean;
  appIconBackgroundColor?: string;
  appNameAnalytics: string;
};

export type AppMenuItems = {
  apps: Array<AppMenuItem>;
};

const DEFAULT_LANGUAGE = 'en-US';

const LANGUAGE_MATCHER = {
  en: 'en-US',
  pt: 'pt-BR',
  es: 'es-419',
  ko: 'ko-KR',
  id: 'id-ID',
};

let languages = {
  'en-US': 'en-US',
  'pt-BR': 'pt-BR',
  'es-419': 'es-419',
  'ko-KR': 'ko-KR',
  'id-ID': 'id-ID',
};

function matchLocale(locale: string) {
  const language = locale.split('-')[0];
  const newLocale = LANGUAGE_MATCHER[language] || DEFAULT_LANGUAGE;

  return newLocale;
}

export function useGenerateAppMenuItems(): Array<AppMenuItem> {
  const { appsWithScope } = useAuthContext();
  const { locale } = useIntl();

  return appsWithScope.map((app: AppsConfiguration) => {
    if (!languages[locale]) {
      const newLocale = matchLocale(locale);
      languages = {
        ...languages,
        [locale]: newLocale,
      };
    }

    const language = languages[locale];
    return {
      appName: app.appName[language] || app.appName[DEFAULT_LANGUAGE],
      appDescription:
        app.appDescription[language] || app.appDescription[DEFAULT_LANGUAGE],
      homeRoute: app.homeRoute,
      baseRoute: app.baseRoute,
      appId: app.id,
      hidden: app.hidden,
      appIconBackgroundColor: app.appIconBackgroundColor,
      appNameAnalytics: app.appName[DEFAULT_LANGUAGE],
    };
  });
}

export default useGenerateAppMenuItems;
