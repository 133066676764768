/* eslint-disable @typescript-eslint/no-explicit-any */
import { NOTIFICATIONS_ENDPOINT } from '../../../constants';
import Api from '../Api';

export const loadNotifications = (
  unread = false,
  priority = false,
  pageNumber?: number,
  pageSize?: number,
): Promise<unknown> => {
  let params = new URLSearchParams({
    sortField: 'createdAt',
    sort: 'DESC',
  })
  if (unread) params.append('read', 'false');
  if (priority) params.append('priority', priority.toString());
  if (pageSize) {params.append('page', pageNumber.toString());
   params.append('pageSize', pageSize.toString());
  }
  const queryString = params.toString();
  let url = `${NOTIFICATIONS_ENDPOINT}?${queryString}`;
  return Api.get({ url });
};

export const unreadNotification = (notificationId: string): Promise<unknown> => {
  return Api.patch({
    url: `${NOTIFICATIONS_ENDPOINT}/${notificationId}/unread`,
  });
};

export const readNotification = (notificationIds: Array<string>): Promise<unknown> => {
  return Api.patch({
    url: `${NOTIFICATIONS_ENDPOINT}/read`,
    body: notificationIds,
  });
};
