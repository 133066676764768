export function convertToPascalCase(str: string) {
  return str
    .replace(/[^a-zA-Z0-9\s]/g, ' ')
    .replace(/\s+(.)/g, (match, chr) => chr.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^\w/, (chr) => chr.toUpperCase());
}

export function getFirstPath(url: string): string {
  const match = url?.match(/\/([^/]*)/);
  return match ? match[0] : url;
}

const sortByDate = (a, b) => {
  return new Date(a).getTime() - new Date(b).getTime();
};

export const getLatestDate = (
  data: Record<string, string>,
  language: string,
) => {
  const languageData = data[language] || data['en-us'];
  if (!languageData) {
    return undefined;
  }
  const dates = Object.keys(languageData).sort(sortByDate);
  return dates[dates.length - 1];
};

export const getTermsAndPoliciesUrl = (
  file: Record<string, string>,
  preferredLanguage: string,
  latestDate: string,
) => {
  return (
    file?.[preferredLanguage]?.[latestDate] ?? file?.['en-us']?.[latestDate]
  );
};
