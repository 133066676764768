import { useCallback, useEffect, useState } from 'react';
import { loadNotifications, readNotification, unreadNotification } from '../../../config/axiosConfig/notification/NotificationApi';
import { INotification } from '../../../notification-dropdown/notification-item/NotificationItemTypes';

interface LoadNotificationInterface {
  unread?: boolean;
  priority?: boolean;
  pageNumber?: number;
  pageSize?: number;
}

interface ChangeNotificationStatusInterface {
  notification: INotification;
}

export const useLoadNotifications = ({
  priority,
  pageNumber = 0,
  pageSize = 10,
  unread,
}: LoadNotificationInterface) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const fetchNotifications = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    setHasError(false);

    const response = await loadNotifications(unread, priority, pageNumber, pageSize) as any

    if (response?.hasError === true) {
      setHasError(true);
    } else {
      setNotifications(response?.data?.content);
    }

    setIsLoading(false);
  }, [unread, priority, pageNumber, pageSize]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  return { notifications, isLoading, hasError, fetchNotifications };
};

export const changeNotificationStatus = async ({
  notification
}: ChangeNotificationStatusInterface) => {
  const response: any = notification.read
    ? await unreadNotification(notification.id) 
    : await readNotification([notification.id]);

  if (response?.hasError === true) {
    return undefined;
  }

  return { ...notification, read: !notification.read };
}
