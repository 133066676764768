import { Select } from '@hexa-ui/components';
import { World } from '@hexa-ui/icons';
import {
  appHeaderConfig,
  CountryData,
  useAppHeader,
  useCountryService,
} from 'admin-portal-shared-services';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

type CountriesMap = {
  [key: string]: CountryData;
};

const CountrySelect = () => {
  const [headerStore] = useAppHeader();
  const { formatMessage, locale } = useIntl();
  const { onChangeSelectedCountry } = appHeaderConfig;

  const { countrySelect, countryOptions, selectedCountry } = headerStore;
  const countryService = useCountryService();
  const countrySelectDisabled = countryOptions?.length === 0;

  const countries: CountriesMap | undefined = useMemo(() => {
    if (countryService.data) {
      return (countryService.data as CountryData[]).reduce(
        (acc: CountriesMap, countryData: CountryData) => ({
          ...acc,
          [countryData.code]: countryData,
        }),
        {},
      );
    }
    return undefined;
  }, [countryService.data]);

  const getCountryName = (countryCode: string): string =>
    countries[countryCode] && countries[countryCode].name[locale?.split('-')[0]]
      ? countries[countryCode].name[locale.split('-')[0]]
      : countryCode;

  if (!countrySelect || !countries) return null;

  return (
    <Select.Root
      size="small"
      shape="pill"
      width="max-content"
      icon={<World size="medium" />}
      disabled={countrySelectDisabled}
      placeholder={
        countrySelectDisabled
          ? formatMessage({
              id: 'inner.header.select.country.placeholder.disabled',
            })
          : formatMessage({ id: 'inner.header.select.country.placeholder' })
      }
      value={selectedCountry}
      onChange={(val) => {
        onChangeSelectedCountry(val);
      }}
    >
      {countryOptions?.map((country) => {
        return (
          <Select.Option key={country} value={country}>
            {getCountryName(country)}
          </Select.Option>
        );
      })}
    </Select.Root>
  );
};

export default CountrySelect;
