import AppIcon from 'components/AppIcon/AppIcon';
import { useEnvContext } from 'contexts';
import { useRef } from 'react';
import { AppIconWrapper, Card, ContentWrapper, Title } from './AppCard.styles';

export interface AppCardProps {
  appName: string;
  appRoute: string;
  iconBackgroundColor: string;
  icon: string;
  onChangeRoute?: () => void;
}

const themeAppColor = {
  tadaPrimary: 'rgb(164, 123, 232)',
};

export const AppCard = ({
  appName,
  appRoute,
  icon,
  iconBackgroundColor,
  onChangeRoute,
}: AppCardProps): JSX.Element => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const { defaultTheme: theme } = useEnvContext();

  return (
    <Card
      ref={ref}
      to={appRoute}
      data-testid="app-card"
      onClick={onChangeRoute}
    >
      <ContentWrapper>
        <AppIconWrapper
          data-testid="app-icon"
          css={{
            backgroundColor: themeAppColor[theme] || iconBackgroundColor,
          }}
        >
          <AppIcon icon={icon} size="small" />
        </AppIconWrapper>
        <Title size="H5" style={{ lineHeight: '20px' }}>
          {appName}
        </Title>
      </ContentWrapper>
    </Card>
  );
};
