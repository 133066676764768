import { Grid } from '@hexa-ui/components';
import { styled, useMatchMedia } from '@hexa-ui/theme';
import { useAppHeader } from 'admin-portal-shared-services';
import { useAppTitleContext } from 'contexts';
import Breadcrumb from './components/Breadcrumb/Breadcrumb';
import CountrySelect from './components/CountrySelect/CountrySelect';
import OptionalButtons from './components/OptionalButtons/OptionalButtons';
import VendorSelect from './components/VendorSelect/VendorSelect';

const InnerHeader = (): JSX.Element => {
  const [isMobile] = useMatchMedia(['tablet']);

  // This is temporary, to give time to all the mfes to set the Header
  const [headerConfig] = useAppHeader();
  const defaultTitle = useAppTitleContext();
  const { vendorSelect, optionalButtons, countrySelect, breadcrumbConfig } =
    headerConfig;
  const hideHeader =
    !vendorSelect &&
    optionalButtons.length === 0 &&
    !countrySelect &&
    !breadcrumbConfig;
  if (hideHeader) return null;

  if (isMobile) return null;

  return (
    <Grid.Container type="fluid" sidebar>
      <StyledContainer>
        <StyledFirstSection>
          <Breadcrumb />
          <StyledPageTitle>
            {headerConfig.pageTitle || defaultTitle}
          </StyledPageTitle>
        </StyledFirstSection>
        <StyledSecondSection
          css={{ rowGap: !vendorSelect && !countrySelect ? '$10' : '$6' }}
        >
          <StyledSelectsContainer>
            <CountrySelect />
            <VendorSelect />
          </StyledSelectsContainer>
          <StyledOptionalButtonsContainer>
            <OptionalButtons />
          </StyledOptionalButtonsContainer>
        </StyledSecondSection>
      </StyledContainer>
    </Grid.Container>
  );
};

export default InnerHeader;

const StyledContainer = styled('div', {
  display: 'flex',
  padding: '24px 0',
  width: '100%',
});

const StyledFirstSection = styled('div', {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$4',
});

const StyledSecondSection = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const StyledPageTitle = styled('h1', {
  backgroundColor: '$interfaceSurfaceClear',
  fontFamily: '$heading',
  height: '40px',
  margin: 0,
  fontSize: '32px',
  marginTop: 'auto',
  fontWeight: 600,
});

const StyledSelectsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: '$4',
});

const StyledOptionalButtonsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'flex-start',
  columnGap: '$4',
});
