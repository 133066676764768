import { LoadingDots } from '@hexa-ui/components';
import { BarChart2, Store3, Web } from '@hexa-ui/icons2';
import { useAuthContext } from 'contexts';
import { useAnalyticsElementClicked } from 'hooks/useAnalyticsElementClicked';
import { App } from 'models/Tool';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  ElementLocationType,
  ElementType,
  PageName,
} from '../../../../../analytics';
import { ToolCard } from '../ToolCard';
import { HomeToolsContainer, LoadingContainer } from './HomeToolCards.styles';

interface IHomeToolCard {
  id: string;
  title: string;
  icon: React.FunctionComponent;
  onClick: () => void;
  isVisible: boolean;
}

interface IHomeToolCardsProps {
  apps: App[];
  isSuccess: boolean;
  isLoading: boolean;
  setAllowedToolsLength: (length: number) => void;
  btGuideUrl: string;
}

export const HomeToolCards = ({
  apps,
  isSuccess,
  isLoading,
  setAllowedToolsLength,
  btGuideUrl,
}: IHomeToolCardsProps) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { appsWithScope } = useAuthContext();
  const analyticsElementClicked = useAnalyticsElementClicked();

  const hasAppAccess = (appIdentifier: string) => {
    return appsWithScope.some((app) => app.id === appIdentifier);
  };

  const getAuthorizationApp = (appIdentifier: string) => {
    return appsWithScope.find(
      (appWithScope) => appWithScope.id === appIdentifier,
    )?.authorizationApp;
  };

  const getAppFromCatalog = (appIdentifier: string) => {
    return apps?.find((app) => app.identifier === appIdentifier);
  };

  const handleHomeToolCardTrack = ({
    userPermissionGroup,
    elementLocationName,
    elementLabel,
    elementName,
  }) => {
    analyticsElementClicked.track({
      isCoreEvent: true,
      elementLocationType: ElementLocationType.Page,
      elementType: ElementType.Button,
      pageName: PageName.Home,
      pageLabel: 'Home',
      elementName,
      userPermissionGroup,
      elementLocationName,
      elementLabel,
    });
  };

  const onReportsCardClick = () => {
    const reportsIdentifier = 'PARTNER-REPORTS';
    const userPermissionGroup = getAuthorizationApp(reportsIdentifier);
    const app = getAppFromCatalog(reportsIdentifier);
    const appUrl = app?.appRoute;
    const appName = app?.name;

    handleHomeToolCardTrack({
      userPermissionGroup,
      elementName: 'HomeToolCard_Analytics',
      elementLocationName: 'ANALYTICS',
      elementLabel: appName,
    });

    navigate(appUrl);
  };

  const onApiaryCardClick = () => {
    const apiaryIdentifier = 'BEESAPIARY';
    const userPermissionGroup = getAuthorizationApp(apiaryIdentifier);
    const app = getAppFromCatalog(apiaryIdentifier);
    const appUrl = app?.appRoute;
    const appName = app?.name;

    handleHomeToolCardTrack({
      userPermissionGroup,
      elementName: 'HomeToolCard_RetailerAppMonitoring',
      elementLocationName: 'RETAILER APP MONITORING',
      elementLabel: appName,
    });

    navigate(appUrl);
  };

  const onBtGuideCardClick = () => {
    const reportsIdentifier = 'PARTNER-REPORTS';
    const userPermissionGroup = getAuthorizationApp(reportsIdentifier);

    handleHomeToolCardTrack({
      userPermissionGroup,
      elementName: 'HomeToolCard_BTGuide',
      elementLocationName: 'BT_GUIDE',
      elementLabel: 'BT Guide',
    });

    window.open(btGuideUrl, '_blank', 'noopener,noreferrer');
  };

  const homeToolCards: IHomeToolCard[] = [
    {
      id: 'reports',
      title: formatMessage({ id: 'Home.accessReportsCard' }),
      icon: () => <BarChart2 />,
      onClick: onReportsCardClick,
      isVisible: hasAppAccess('PARTNER-REPORTS'),
    },
    {
      id: 'retailerApp',
      title: formatMessage({ id: 'Home.trackDataCard' }),
      icon: () => <Store3 />,
      onClick: onApiaryCardClick,
      isVisible: hasAppAccess('BEESAPIARY'),
    },
    {
      id: 'btGuide',
      title: formatMessage({ id: 'Home.btGuideCard' }),
      icon: () => <Web />,
      onClick: onBtGuideCardClick,
      isVisible: hasAppAccess('PARTNER-REPORTS') && !!btGuideUrl,
    },
  ];

  const allowedHomeTools = homeToolCards.filter((tool) => tool.isVisible);

  useEffect(() => {
    setAllowedToolsLength(allowedHomeTools.length);
  }, [allowedHomeTools, setAllowedToolsLength]);

  if (allowedHomeTools.length > 0 && isLoading) {
    return (
      <LoadingContainer>
        <LoadingDots data-testid="loading-dots" size="xxlarge" />
      </LoadingContainer>
    );
  }

  if (isSuccess && allowedHomeTools.length > 0) {
    return (
      <HomeToolsContainer
        length={allowedHomeTools.length === 1 ? 'single' : 'multiple'}
      >
        {allowedHomeTools.map((tool) => (
          <ToolCard
            key={tool.id}
            icon={tool.icon}
            title={tool.title}
            onClick={tool.onClick}
          />
        ))}
      </HomeToolsContainer>
    );
  }

  return <></>;
};
