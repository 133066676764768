import { Icon, Typography } from '@hexa-ui/components2';
import { FunctionComponent } from 'react';
import { CardContent, CardStyled } from './ToolCard.styles';

export interface ToolCardProps {
  icon: FunctionComponent;
  title: string;
  onClick: () => void;
}

export const ToolCard = ({ icon, title, onClick }: ToolCardProps) => {
  return (
    <CardStyled border="medium" elevated="minimal" onClick={onClick}>
      <CardContent>
        <Icon icon={icon} size="large" />
        <Typography.Header variant="h4" style={{ maxWidth: '235px' }}>
          {title}
        </Typography.Header>
      </CardContent>
    </CardStyled>
  );
};
