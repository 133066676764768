export function isPartnerApp(url: string) {
  const partnerUrlChunks = [
    'partner.mybees',
    'partner-dev',
    'partner-sit',
    'partner-uat',
  ];

  return partnerUrlChunks.some((chunk) => url.includes(chunk));
}
