import { Card as HexaCard } from '@hexa-ui/components';
import { Typography } from '@hexa-ui/components2';
import { styled } from '@hexa-ui/theme';
import { breakpoints } from 'utils/constants';

export const Card = styled(HexaCard, {
  width: '100%',
  padding: '20px 0px',
  display: 'flex',
  flexDirection: 'column',
  variants: {
    isSearchOpen: {
      true: {
        height: 'max-content',
        [`@media (min-width: ${breakpoints.desktopSmall})`]: {
          height: '436px',
        },
      },
      false: {
        height: 'max-content',
        [`@media (min-width: ${breakpoints.desktopSmall})`]: {
          height: '368px',
        },
      },
    },
  },
});

export const AppsWrapper = styled('div', {
  padding: '16px 16px 16px 24px ',
  maxHeight: '256px',
  overflowY: 'auto',
  overflowX: 'hidden',
  width: '100%',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(219, 218, 218, 1) transparent',
  scrollbarGutter: 'stable',

  '&::-webkit-scrollbar': {
    width: '12px',
  },

  '&::-webkit-scrollbar-track': {
    background: 'transparent',
    borderRadius: '10px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(219, 218, 218, 1)',
    borderRadius: '10px',
  },

  [`@media (min-width: ${breakpoints.desktopSmall})`]: {
    height: '256px',
  },
});

export const AppsGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  columnGap: '12px',
  rowGap: '16px',

  [`@media (min-width: ${breakpoints.mobileLarge})`]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [`@media (min-width: ${breakpoints.tablet})`]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  [`@media (min-width: ${breakpoints.desktopSmall})`]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [`@media (min-width: ${breakpoints.desktopLarge})`]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
});

export const Header = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 24px',
});

export const SearchContainer = styled('div', {
  display: 'flex',
  columnGap: '10px',
  margin: '20px 24px 8px',
  alignItems: 'center',
  maxHeight: '40px',
});

export const FilterWrapper = styled('div', {
  flexShrink: 0,
  position: 'relative',
});

export const StyledFilterLabel = styled(Typography.Body, {
  margin: 0,
  display: 'none',

  [`@media (min-width: ${breakpoints.mobileLarge})`]: {
    display: 'flex',
  },
});

export const SearchFieldWrapper = styled('div', {
  flexGrow: 1,
  '> div': {
    height: '40px',
  },
});

export const LoadingContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
});

export const ExceptionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  gap: '16px',
});
