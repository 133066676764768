import { forwardRef, Ref } from 'react';
import {
  DropdownMenuStyled,
  GroupName,
  MenuOption,
} from './DropdownMenu.styles';

type MenuOption = {
  label: string;
  value: string;
  onClick: () => void;
};

type DropdownMenuProps = {
  menuOptions: {
    id: number;
    groupName: string;
    options: MenuOption[];
  }[];
  handleClose: () => void;
};

export const DropdownMenu = forwardRef(
  (
    { menuOptions, handleClose }: DropdownMenuProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <DropdownMenuStyled ref={ref} data-testid="dropdown-menu">
        {menuOptions?.map((menuOption) => (
          <div key={menuOption.id}>
            <GroupName>{menuOption.groupName}</GroupName>
            {menuOption?.options?.map((option) => (
              <MenuOption
                key={option.value}
                tabIndex={0}
                onClick={() => {
                  option.onClick();
                  handleClose();
                }}
                data-testid={`sort-option-${option.value}`}
              >
                {option.label}
              </MenuOption>
            ))}
          </div>
        ))}
      </DropdownMenuStyled>
    );
  },
);

DropdownMenu.displayName = 'DropdownMenu';
