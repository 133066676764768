export enum NotificationsEnumInterface {
  SettingsIcon = 'SETTINGS_ICON',
  LevelGeneral = 'GENERAL',
  LevelPriority = 'PRIORITY',
  ReadStatus = 'READ',
  UnreadStatus = 'UNREAD',
  tabRead = 'IN_APP_NOTIFICATIONS_READ',
  tabNew = 'IN_APP_NOTIFICATIONS_NEW',
  entryMethod = 'SETTINGS_ICON',
}

export enum ScreenNameEnumInterface {
  CompanyManagement = 'COMPANY_MANAGEMENT',
  Coverage = 'COVERAGE',
  Dashboard = 'DASHBOARD',
  HomePage = 'HOMEPAGE',
  Inventory = 'INVENTORY',
  ReportBehavivor = 'REPORT_BEHAHIVOUR',
  ReportCustomer = 'REPORT_CUSTOMER',
  ReportOrders = 'REPORT_ORDERS',
  Settings = 'SETTINGS',
  TermsAndPrivacy = 'TERMS_AND_PRIVACY',
  TermsOfService = 'TERMS_OF_SERVICE',
  DistributionCenter = 'DISTRIBUTION_CENTER',
  GetHelp = 'GET_HELP',
}

export enum NotificationTabs {
  unreadTab = 'unreadTab',
  allTab = 'allTab',
  highPriorityTab = 'highPriorityTab',

}

export const NOTIFICATIONS_ENDPOINT = '/api/v1/notification-center-service/notifications';

export const MAX_NOTIFICATIONS_PER_PAGE = 5;

export const MAX_PAGE_SIZE = 100;
