import { Portal } from '@hexa-ui/components';
import { ThemeProvider as ThemeProvider2 } from '@hexa-ui/components2';
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { generateIncrementalCounter } from 'admin-portal-shared-services';
import { OneThemeProvider } from 'components/OneThemeProvider/OneThemeProvider';
import {
  AppTitleProvider,
  AuthProvider,
  EnvConfig,
  EnvProvider,
  NotificationProvider,
  PreferredLanguageProvider,
  SidebarProvider,
} from 'contexts';
import { BrowserRouter } from 'react-router-dom';
import { defaultTheme as supplierDefaultTheme } from 'supplier-portal-shared-components';
import InnerHeader from './components/InnerHeader/InnerHeader';
import {
  useDatadog,
  useInstantiateSegmentAnalytics,
  useLoadFeatureToggle,
  useNewRelic,
} from './hooks';
import IntlProvider from './i18n/provider';
import Routes from './Routes';

const counterGenerator = generateIncrementalCounter('main-mfe');

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

export default function App(props: EnvConfig) {
  const { datadogAppId, datadogClientId, defaultTheme, segmentKey } = props;
  useLoadFeatureToggle();
  useNewRelic();
  useDatadog(datadogAppId, datadogClientId);
  useInstantiateSegmentAnalytics(segmentKey);

  const generateClassName = createGenerateClassName({
    productionPrefix: 'admin-portal-main-mfe',
    seed: 'admin-portal-main-mfe',
    customGetNextCounterId: counterGenerator,
  });

  return (
    <QueryClientProvider client={queryClient}>
      <SidebarProvider>
        <NotificationProvider>
          <EnvProvider env={props}>
            <AuthProvider>
              <ThemeProvider theme={supplierDefaultTheme}>
                <ThemeProvider2>
                  <StylesProvider generateClassName={generateClassName}>
                    <PreferredLanguageProvider>
                      <IntlProvider>
                        <BrowserRouter>
                          <OneThemeProvider portalConfigTheme={defaultTheme}>
                            <AppTitleProvider>
                              <Portal
                                container={document.getElementById(
                                  'mfe-content-header',
                                )}
                              >
                                <InnerHeader />
                              </Portal>
                              <Routes />
                            </AppTitleProvider>
                          </OneThemeProvider>
                        </BrowserRouter>
                      </IntlProvider>
                    </PreferredLanguageProvider>
                  </StylesProvider>
                </ThemeProvider2>
              </ThemeProvider>
            </AuthProvider>
          </EnvProvider>
        </NotificationProvider>
      </SidebarProvider>
    </QueryClientProvider>
  );
}
