import { Icon, TextLink, Tooltip, Typography } from '@hexa-ui/components2';
import { AlertTriangle } from '@hexa-ui/icons2';
import { INotification } from 'components/Notification/notification-dropdown/notification-item/NotificationItemTypes';
import { timeSince } from 'components/Notification/utils/notificationContentUtils/notificationContentUtils';
import { useAnalyticsElementClicked } from 'hooks/useAnalyticsElementClicked';
import { useGetApps } from 'hooks/useGetApps';
import { useIntl } from 'react-intl';
import {
  ElementLocationType,
  ElementType,
  PageName,
} from '../../../../../analytics';
import { changeNotificationStatus } from '../../../../components/Notification/services/notification/newLoadNotification/newLoadNotificationService';
import {
  MessageContainer,
  Notification,
  StatusButton,
  StatusContainer,
  TitleContainer,
  ToolContainer,
} from './NotificationItem.styles';

type NotificationItemProps = {
  notification: INotification;
  updateNotifications: (notification: INotification) => void;
};

export const NotificationItem = ({
  notification,
  updateNotifications,
}: NotificationItemProps): JSX.Element => {
  const { formatMessage, locale } = useIntl();
  const { data: allApps } = useGetApps(locale);
  const analyticsElementClicked = useAnalyticsElementClicked();

  const updateNotificationStatus = async (notification: INotification) => {
    const updatedNotification = await changeNotificationStatus({
      notification,
    });

    if (updatedNotification) {
      updateNotifications(updatedNotification);
    }

    analyticsElementClicked.track({
      isCoreEvent: true,
      elementLocationType: ElementLocationType.Page,
      elementLocationName: 'LATEST_NOTIFICATIONS_ITEM',
      elementLabel: notification.read ? 'Unread' : 'Read',
      elementName: 'LatestNotificationsItem_StatusButton',
      elementType: ElementType.Button,
      pageLabel: 'Home',
      pageName: PageName.Home,
    });
    return;
  };

  const getAppName = (notificationName: string) => {
    const regex = new RegExp(/^\[(.*?)\]/);
    const match = regex.exec(notificationName);

    const appId = match ? match[1] : null;

    if (!appId) return;

    return (
      allApps?.data?.content.find((app) => app.identifier === appId)?.name || ''
    );
  };

  const trackShowMore = () => {
    analyticsElementClicked.track({
      isCoreEvent: true,
      elementLocationType: ElementLocationType.Page,
      elementLocationName: 'LATEST_NOTIFICATIONS_ITEM',
      elementLabel: 'Show more',
      elementName: 'LatestNotificationsItem_ShowMoreLink',
      elementType: ElementType.Link,
      pageLabel: 'Home',
      pageName: PageName.Home,
    });
  };

  return (
    <Notification key={notification.id}>
      <ToolContainer>
        <Typography.SubHeader style={{ margin: '0px 0px 4px' }}>
          {getAppName(notification.notificationName)}
        </Typography.SubHeader>
        <StatusContainer>
          <Tooltip
            content={formatMessage({
              id: notification.read
                ? 'Notification.markUnread'
                : 'Notification.markRead',
            })}
            position="right"
          >
            <StatusButton
              onClick={() => updateNotificationStatus(notification)}
              read={notification.read}
              data-testid="notification-status-button"
              data-state={notification.read ? 'read' : 'unread'}
            />
          </Tooltip>
          <Typography.Body style={{ margin: '0px', color: '#1414148f' }}>
            {timeSince(notification.createdAt, locale, formatMessage)}
          </Typography.Body>
        </StatusContainer>
      </ToolContainer>
      <MessageContainer>
        <TitleContainer>
          <Typography.SubHeader style={{ margin: '0px' }}>
            {notification.title}
          </Typography.SubHeader>
          {notification.priority && (
            <Tooltip
              content={formatMessage({
                id: 'Notification.highPriority',
              })}
              position="top"
            >
              <div style={{ alignSelf: 'baseline' }}>
                <Icon icon={AlertTriangle} style={{ color: '#D47300FF' }} />
              </div>
            </Tooltip>
          )}
        </TitleContainer>
        <Typography.Body style={{ margin: '2px 0px 0px', color: '#1414148f' }}>
          {notification.formattedMessage}
        </Typography.Body>
        {!!notification?.inAppUrl && (
          <TextLink
            href={notification.inAppUrl}
            style={{ marginTop: '8px', width: 'fit-content' }}
            onClick={() => trackShowMore()}
          >
            {formatMessage({ id: 'Notification.showMore' })}
          </TextLink>
        )}
      </MessageContainer>
    </Notification>
  );
};

export default NotificationItem;
