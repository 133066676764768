import { Heading } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import tokens from '@hexa-ui/tokens/dist/json/nested.json';
import { Link } from 'react-router-dom';

export const Card = styled(Link, {
  border: `1px solid ${tokens.color.foundation.border.secondary}`,
  width: '100%',
  height: '64px',
  padding: '12px',
  textDecoration: 'inherit',
  cursor: 'pointer',
  borderRadius: '$4',
  display: 'flex',
  background: '#fff',
  '&:hover': {
    boxShadow: tokens.shadow.elevation[2],
  },
  '&:focus': {
    boxShadow: tokens.shadow.elevation[2],
  },
});

export const ContentWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const AppIconWrapper = styled('div', {
  height: '2rem',
  width: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  flexShrink: 0,
});

export const Title = styled(Heading, {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': 2,
});
