const AuditLogLogo = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_750_6955)">
      <circle cx="24" cy="24" r="24" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 36C31.757 36 34 33.757 34 31V17C34 14.243 31.757 12 29 12H19C16.243 12 14 14.243 14 17V31C14 33.757 16.243 36 19 36H29ZM16 17C16 15.346 17.346 14 19 14H29C30.654 14 32 15.346 32 17V31C32 32.654 30.654 34 29 34H19C17.346 34 16 32.654 16 31V17ZM30 18C30 17.448 29.552 17 29 17H25C24.448 17 24 17.448 24 18C24 18.552 24.448 19 25 19H29C29.552 19 30 18.552 30 18ZM29 23C29.552 23 30 23.448 30 24C30 24.552 29.552 25 29 25H25C24.448 25 24 24.552 24 24C24 23.448 24.448 23 25 23H29ZM30 30C30 29.448 29.552 29 29 29H25C24.448 29 24 29.448 24 30C24 30.552 24.448 31 25 31H29C29.552 31 30 30.552 30 30ZM22 17V19C22 19.552 21.552 20 21 20H19C18.448 20 18 19.552 18 19V17C18 16.448 18.448 16 19 16H21C21.552 16 22 16.448 22 17ZM22 25V23C22 22.448 21.552 22 21 22H19C18.448 22 18 22.448 18 23V25C18 25.552 18.448 26 19 26H21C21.552 26 22 25.552 22 25ZM22 29V31C22 31.552 21.552 32 21 32H19C18.448 32 18 31.552 18 31V29C18 28.448 18.448 28 19 28H21C21.552 28 22 28.448 22 29Z"
        fill="#141414"
      />
    </g>
    <defs>
      <clipPath id="clip0_750_6955">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AuditLogLogo;
