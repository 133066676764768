import { useAuthenticationService as authenticationService } from 'admin-portal-shared-services';

const isValidStringJson = (data: string) => {
  try {
    JSON.parse(data);
  } catch (e) {
    return false;
  }
  return true;
};

const getAnonymousId = (): string | null => {
  const anonymousId = localStorage.getItem('ajs_anonymous_id');

  if (anonymousId && typeof anonymousId === 'string') {
    return isValidStringJson(anonymousId)
      ? JSON.parse(anonymousId)
      : anonymousId;
  }
  return null;
};

export const getUserIds = () => ({
  anonymousId: getAnonymousId(),
  userId: authenticationService().getUserId(),
});
