const BeesDataPortalLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="-24 -24 112 112"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Brand/Hub/AppIcon/BeesDataPortal</title>
    <defs>
      <path
        d="M 47.6667 24 C 49.0342 24 50.1614 25.0294 50.3154 26.3557 L 50.3333 26.6667 L 50.3333 53.3333 C 50.3333 54.8061 49.1394 56 47.6667 56 C 46.2991 56 45.172 54.9706 45.0179 53.6443 L 45 53.3333 L 45 26.6667 C 45 25.1939 46.1939 24 47.6667 24 Z M 31.6667 8 C 33.0342 8 34.1614 9.02944 34.3154 10.3557 L 34.3333 10.6667 L 34.3333 53.3333 C 34.3333 54.8061 33.1394 56 31.6667 56 C 30.2991 56 29.172 54.9706 29.0179 53.6443 L 29 53.3333 L 29 10.6667 C 29 9.19391 30.1939 8 31.6667 8 Z M 15.6667 34.6667 C 17.0342 34.6667 18.1614 35.6961 18.3154 37.0223 L 18.3333 37.3333 L 18.3333 53.3333 C 18.3333 54.8061 17.1394 56 15.6667 56 C 14.2991 56 13.172 54.9706 13.0179 53.6443 L 13 53.3333 L 13 37.3333 C 13 35.8606 14.1939 34.6667 15.6667 34.6667 Z"
        id="bees-data-portal-path"
      />
    </defs>
    <g
      id="Brand/Hub/AppIcon/BeesDataPortal"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Avatar/BeesDataPortal">
        <mask id="bees-data-portal-mask-2" fill="white">
          <use xlinkHref="#bees-data-portal-path" />
        </mask>
        <use
          id="Shape"
          fill="#000000"
          fillRule="nonzero"
          xlinkHref="#bees-data-portal-path"
        />
      </g>
    </g>
  </svg>
);

export default BeesDataPortalLogo;
