import { Error500 as Error500Page } from '@hexa-ui/components';
import { useIntl } from 'react-intl';

export const Error500 = (): JSX.Element => {
  const intl = useIntl();

  return (
    <Error500Page headerText={intl.formatMessage({ id: 'Error500.title' })} />
  );
};

export default Error500;
