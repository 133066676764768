const ExperimentationPlatformLogo = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2232_65835)">
      <circle cx="24" cy="24" r="24" fill="#FFFF00" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5417 13.1334H19.9998C19.3739 13.1334 18.8665 12.626 18.8665 12C18.8665 11.3741 19.3739 10.8667 19.9998 10.8667H27.9998C28.6258 10.8667 29.1332 11.3741 29.1332 12C29.1332 12.626 28.6258 13.1334 27.9998 13.1334H27.4584V24.2631C27.4584 24.4684 27.5447 24.6813 27.7246 24.8501L34.7566 31.449C35.864 32.4882 36.0375 33.9426 35.5219 35.1108C35.0103 36.2697 33.8337 37.1334 32.337 37.1334H15.663C14.1664 37.1334 12.9897 36.2697 12.4782 35.1108C11.9625 33.9426 12.1361 32.4882 13.2435 31.449L20.2754 24.8502C20.4554 24.6813 20.5417 24.4684 20.5417 24.2631V13.1334ZM22.8084 13.1334V24.2631C22.8084 25.1178 22.4458 25.9219 21.8264 26.5031L14.7945 33.1019C14.4372 33.4372 14.3971 33.8451 14.5518 34.1955C14.7106 34.5553 15.0883 34.8667 15.663 34.8667H32.337C32.9117 34.8667 33.2894 34.5553 33.4482 34.1955C33.6029 33.8451 33.5628 33.4372 33.2055 33.1019L26.1737 26.5031C25.5543 25.922 25.1917 25.1179 25.1917 24.2631V13.1334H22.8084Z"
        fill="#141414"
      />
    </g>
    <defs>
      <clipPath id="clip0_2232_65835">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default ExperimentationPlatformLogo;
